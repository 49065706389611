.homeDetail__section {
  margin-top: 5%;
  margin-bottom: 5%;

  @media screen and (min-width: 275px) {
    margin-top: -15%;
    padding-left: 0;
    padding-right: 0;
  }
  @media screen and (min-width: 375px) {
    margin-top: -15%;
    padding-left: 0;
    padding-right: 0;
  }
  @media screen and (min-width: 696px) {
    margin-top: -15%;
    padding-left: 0;
    padding-right: 0;
  }
  @media screen and (min-width: 700px) {
    margin-top: 25%;
    margin-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }
  @media screen and (min-width: 800px) {
    margin-top: 25%;
    margin-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }
  @media screen and (min-width: 1300px) {
    margin-top: 30%;
    margin-bottom: 5%;
    padding-left: 0;
    padding-right: 0;
  }
  @media screen and (min-width: 1420px) {
    margin-top: 30%;
    margin-bottom: 5%;
    padding-left: 0;
    padding-right: 0;
  }
  @media screen and (min-width: 1600px) {
    margin-top: 5%;
    margin-bottom: 5%;
    padding-left: 0;
    padding-right: 0;
  }
  @media screen and (min-width: 1900px) {
    margin-top: 5%;
    margin-bottom: 5%;
    padding-left: 0;
    padding-right: 0;
  }
  @media screen and (min-width: 2500px) {
    margin-top: 5%;
    margin-bottom: 5%;
    padding-left: 430px;
    padding-right: 430px;
  }

  @media screen and (min-width: 3000px) {
    margin-top: 5%;
    margin-bottom: 5%;
    padding-left: 830px;
    padding-right: 830px;
  }

  .homeDetail__wrapper {
    display: grid;
    grid-template-columns: 30% auto;
    grid-template-rows: 480px;
    grid-template-areas: 'heading servicesSection';

    @media screen and (min-width: 275px) {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-areas: none;
      grid-template-rows: 480px;
    }
    @media screen and (min-width: 375px) {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-areas: none;
      grid-template-rows: 480px;
    }
    @media screen and (min-width: 696px) {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-areas: none;
      grid-template-rows: 480px;
    }
    @media screen and (min-width: 700px) {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-areas: none;
      grid-template-rows: 480px;
    }
    @media screen and (min-width: 800px) {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-areas: none;
      grid-template-rows: 480px;
    }
    @media screen and (min-width: 1300px) {
      display: grid;
      grid-template-columns: 15% auto;
      grid-template-rows: 480px;
      grid-template-areas: 'heading servicesSection';
    }
    @media screen and (min-width: 1420px) {
      display: grid;
      grid-template-columns: 15% auto;
      grid-template-rows: 480px;
      grid-template-areas: 'heading servicesSection';
    }
    @media screen and (min-width: 1600px) {
      display: grid;
      grid-template-columns: 30% auto;
      grid-template-rows: 480px;
      grid-template-areas: 'heading servicesSection';
    }
    @media screen and (min-width: 1900px) {
      display: grid;
      grid-template-columns: 30% auto;
      grid-template-rows: 480px;
      grid-template-areas: 'heading servicesSection';
    }

    @media screen and (min-width: 2500px) {
      display: grid;
      grid-template-columns: 30% auto;
      grid-template-rows: 480px;
      grid-template-areas: 'heading servicesSection';
    }

    @media screen and (min-width: 3000px) {
      display: grid;
      grid-template-columns: 30% auto;
      grid-template-rows: 480px;
      grid-template-areas: 'heading servicesSection';
    }

    .homeDetail__heading {
      grid-area: heading;
      display: flex;
      justify-content: flex-end;
      padding-top: 80px;
      padding-bottom: 80px;
      opacity: 0;

      @media screen and (min-width: 275px) {
        grid-area: heading;
        display: flex;
        justify-content: flex-end;
        padding-top: 0;
        padding-bottom: 0;
        margin-left: 0;
      }
      @media screen and (min-width: 375px) {
        grid-area: heading;
        display: flex;
        justify-content: flex-end;
        padding-top: 0;
        padding-bottom: 0;
        margin-left: 0;
      }
      @media screen and (min-width: 696px) {
        grid-area: heading;
        display: flex;
        justify-content: flex-end;
        padding-top: 0;
        padding-bottom: 0;
        margin-left: 0;
      }
      @media screen and (min-width: 700px) {
        grid-area: heading;
        display: flex;
        justify-content: flex-start;
        padding-top: 0;
        padding-bottom: 5rem;
        margin-left: 7rem;
      }
      @media screen and (min-width: 800px) {
        grid-area: heading;
        display: flex;
        justify-content: flex-start;
        padding-top: 0;
        padding-bottom: 5rem;
        margin-left: 8.5rem;
      }
      @media screen and (min-width: 1300px) {
        grid-area: heading;
        display: flex;
        justify-content: flex-end;
        padding-top: 80px;
        padding-bottom: 80px;
        margin-left: 16rem;
      }
      @media screen and (min-width: 1420px) {
        grid-area: heading;
        display: flex;
        justify-content: flex-end;
        padding-top: 80px;
        padding-bottom: 80px;
        margin-left: 17rem;
      }
      @media screen and (min-width: 1600px) {
        grid-area: heading;
        display: flex;
        justify-content: flex-end;
        padding-top: 80px;
        padding-bottom: 80px;
        margin-left: 0;
      }

      @media screen and (min-width: 1715px) {
        grid-area: heading;
        display: flex;
        justify-content: flex-end;
        padding-top: 80px;
        padding-bottom: 80px;
        margin-left: 0;
      }

      @media screen and (min-width: 1900px) {
        grid-area: heading;
        display: flex;
        justify-content: flex-end;
        padding-top: 80px;
        padding-bottom: 80px;
        margin-left: 0;
      }

      @media screen and (min-width: 2500px) {
        grid-area: heading;
        display: flex;
        justify-content: flex-end;
        padding-top: 80px;
        padding-bottom: 80px;
        margin-left: 0;
      }

      @media screen and (min-width: 3000px) {
        grid-area: heading;
        display: flex;
        justify-content: flex-end;
        padding-top: 80px;
        padding-bottom: 80px;
        margin-left: 0;
      }

      h1 {
        text-transform: uppercase;
        max-width: 330px;
        font-size: 40px;
        font-weight: 600;
        letter-spacing: 2px;

        @media screen and (min-width: 275px) {
          text-transform: uppercase;
          max-width: 330px;
          font-size: 30px;
          font-weight: 600;
          letter-spacing: 2px;
        }
        @media screen and (min-width: 375px) {
          text-transform: uppercase;
          max-width: 330px;
          font-size: 30px;
          font-weight: 600;
          letter-spacing: 2px;
        }
        @media screen and (min-width: 696px) {
          text-transform: uppercase;
          max-width: 330px;
          font-size: 30px;
          font-weight: 600;
          letter-spacing: 2px;
        }
        @media screen and (min-width: 700px) {
          text-transform: uppercase;
          max-width: 330px;
          font-size: 30px;
          font-weight: 600;
          letter-spacing: 2px;
        }
        @media screen and (min-width: 800px) {
          text-transform: uppercase;
          max-width: 330px;
          font-size: 30px;
          font-weight: 600;
          letter-spacing: 2px;
        }
        @media screen and (min-width: 1300px) {
          text-transform: uppercase;
          max-width: 330px;
          font-size: 30px;
          font-weight: 600;
          letter-spacing: 2px;
        }
        @media screen and (min-width: 1420px) {
          text-transform: uppercase;
          max-width: 330px;
          font-size: 30px;
          font-weight: 600;
          letter-spacing: 2px;
        }
        @media screen and (min-width: 1600px) {
          text-transform: uppercase;
          max-width: 330px;
          font-size: 40px;
          font-weight: 600;
          letter-spacing: 2px;
        }

        @media screen and (min-width: 1715px) {
          text-transform: uppercase;
          max-width: 330px;
          font-size: 40px;
          font-weight: 600;
          letter-spacing: 2px;
        }

        @media screen and (min-width: 1900px) {
          text-transform: uppercase;
          max-width: 330px;
          font-size: 40px;
          font-weight: 600;
          letter-spacing: 2px;
        }

        @media screen and (min-width: 2500px) {
          text-transform: uppercase;
          max-width: 330px;
          font-size: 40px;
          font-weight: 600;
          letter-spacing: 2px;
        }

        @media screen and (min-width: 3000px) {
          text-transform: uppercase;
          max-width: 330px;
          font-size: 40px;
          font-weight: 600;
          letter-spacing: 2px;
        }
      }
    }

    .homeDetail__services_wrapper {
      grid-area: servicesSection;

      .homeDetail__services {
        height: 100%;
        max-width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;
        padding-top: 85px;
        padding-bottom: 80px;
        padding-left: 140px;

        @media screen and (min-width: 275px) {
          display: block;
          flex-direction: column;
          justify-content: flex-start;
          flex-wrap: none;
          padding-top: 140px;
          padding-bottom: 80px;
          height: 100%;
          max-width: 100%;
          padding-left: 0;
        }
        @media screen and (min-width: 375px) {
          display: block;
          flex-direction: column;
          justify-content: flex-start;
          flex-wrap: none;
          padding-top: 140px;
          padding-bottom: 80px;
          height: 100%;
          max-width: 100%;
          padding-left: 0;
        }
        @media screen and (min-width: 696px) {
          display: block;
          flex-direction: column;
          justify-content: flex-start;
          flex-wrap: none;
          padding-top: 140px;
          padding-bottom: 80px;
          height: 100%;
          max-width: 100%;
          padding-left: 0;
        }
        @media screen and (min-width: 700px) {
          height: 100%;
          max-width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          flex-wrap: wrap;
          padding-top: 140px;
          padding-bottom: 80px;
          padding-left: 120px;
        }
        @media screen and (min-width: 800px) {
          height: 100%;
          max-width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          flex-wrap: wrap;
          padding-top: 140px;
          padding-bottom: 80px;
          padding-left: 140px;
        }
        @media screen and (min-width: 1300px) {
          height: 100%;
          max-width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          flex-wrap: wrap;
          padding-top: 85px;
          padding-bottom: 80px;
          padding-left: 140px;
        }
        @media screen and (min-width: 1420px) {
          height: 100%;
          max-width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          flex-wrap: wrap;
          padding-top: 85px;
          padding-bottom: 80px;
          padding-left: 140px;
        }
        @media screen and (min-width: 1600px) {
          height: 100%;
          max-width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          flex-wrap: wrap;
          padding-top: 85px;
          padding-bottom: 80px;
          padding-left: 140px;
        }

        @media screen and (min-width: 1715px) {
          height: 100%;
          max-width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          flex-wrap: wrap;
          padding-top: 85px;
          padding-bottom: 80px;
          padding-left: 140px;
        }

        @media screen and (min-width: 1900px) {
          height: 100%;
          max-width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          flex-wrap: wrap;
          padding-top: 85px;
          padding-bottom: 80px;
          padding-left: 140px;
        }

        @media screen and (min-width: 2500px) {
          height: 100%;
          max-width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          flex-wrap: wrap;
          padding-top: 85px;
          padding-bottom: 80px;
          padding-left: 140px;
        }

        @media screen and (min-width: 3000px) {
          height: 100%;
          max-width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          flex-wrap: wrap;
          padding-top: 85px;
          padding-bottom: 80px;
          padding-left: 140px;
        }

        .homeDetail__service {
          flex-direction: column;
          max-width: 280px;
          margin-right: 60px;
          opacity: 0;

          @media screen and (min-width: 275px) {
            flex-direction: column;
            max-width: 280px;
            margin-right: 60px;
            padding-bottom: 30px;
          }
          @media screen and (min-width: 375px) {
            flex-direction: column;
            max-width: 280px;
            margin-right: 60px;
            padding-bottom: 30px;
          }
          @media screen and (min-width: 696px) {
            flex-direction: column;
            max-width: 280px;
            margin-right: 60px;
            padding-bottom: 30px;
          }
          @media screen and (min-width: 700px) {
            flex-direction: column;
            max-width: 280px;
            margin-right: 20px;
            min-height: 160px;
          }
          @media screen and (min-width: 800px) {
            flex-direction: column;
            max-width: 280px;
            margin-right: 60px;
            min-height: 160px;
          }
          @media screen and (min-width: 1300px) {
            flex-direction: column;
            max-width: 280px;
            margin-right: 60px;
            min-height: 160px;
          }
          @media screen and (min-width: 1420px) {
            flex-direction: column;
            max-width: 280px;
            margin-right: 60px;
            min-height: 160px;
          }
          @media screen and (min-width: 1600px) {
            flex-direction: column;
            max-width: 280px;
            margin-right: 60px;
            min-height: 180px;
          }

          @media screen and (min-width: 1715px) {
            flex-direction: column;
            max-width: 280px;
            margin-right: 60px;
            min-height: 180px;
          }

          @media screen and (min-width: 1900px) {
            flex-direction: column;
            max-width: 280px;
            margin-right: 60px;
            min-height: none;
          }

          @media screen and (min-width: 2500px) {
            flex-direction: column;
            max-width: 280px;
            margin-right: 60px;
            min-height: none;
          }

          @media screen and (min-width: 3000px) {
            flex-direction: column;
            max-width: 280px;
            margin-right: 60px;
            min-height: none;
          }

          h1 {
            text-transform: uppercase;
            font-size: 18px;
            padding-bottom: 10px;
            margin-left: 20px;
            letter-spacing: 1px;

            @media screen and (min-width: 275px) {
              text-transform: uppercase;
              font-size: 15px;
              padding-bottom: 10px;
              margin-left: 20px;
              letter-spacing: 1px;
            }
            @media screen and (min-width: 375px) {
              text-transform: uppercase;
              font-size: 15px;
              padding-bottom: 10px;
              margin-left: 20px;
              letter-spacing: 1px;
            }
            @media screen and (min-width: 696px) {
              text-transform: uppercase;
              font-size: 15px;
              padding-bottom: 10px;
              margin-left: 20px;
              letter-spacing: 1px;
            }
            @media screen and (min-width: 700px) {
              text-transform: uppercase;
              font-size: 15px;
              padding-bottom: 10px;
              margin-left: 20px;
              letter-spacing: 1px;
            }
            @media screen and (min-width: 800px) {
              text-transform: uppercase;
              font-size: 15px;
              padding-bottom: 10px;
              margin-left: 20px;
              letter-spacing: 1px;
            }
            @media screen and (min-width: 1300px) {
              text-transform: uppercase;
              font-size: 15px;
              padding-bottom: 10px;
              margin-left: 20px;
              letter-spacing: 1px;
            }
            @media screen and (min-width: 1420px) {
              text-transform: uppercase;
              font-size: 15px;
              padding-bottom: 10px;
              margin-left: 20px;
              letter-spacing: 1px;
            }
            @media screen and (min-width: 1600px) {
              text-transform: uppercase;
              font-size: 18px;
              padding-bottom: 10px;
              margin-left: 20px;
              letter-spacing: 1px;
            }

            @media screen and (min-width: 1715px) {
              text-transform: uppercase;
              font-size: 18px;
              padding-bottom: 10px;
              margin-left: 20px;
              letter-spacing: 1px;
            }

            @media screen and (min-width: 1900px) {
              text-transform: uppercase;
              font-size: 18px;
              padding-bottom: 10px;
              margin-left: 20px;
              letter-spacing: 1px;
            }

            @media screen and (min-width: 2500px) {
              text-transform: uppercase;
              font-size: 18px;
              padding-bottom: 10px;
              margin-left: 20px;
              letter-spacing: 1px;
            }

            @media screen and (min-width: 3000px) {
              text-transform: uppercase;
              font-size: 18px;
              padding-bottom: 10px;
              margin-left: 20px;
              letter-spacing: 1px;
            }
          }

          .vertLine {
            content: '';
            position: absolute;
            margin-top: 4px;
            height: 15px;
            width: 3px;
            background: var(--secondary-color);
          }

          p {
            text-transform: uppercase;
            font-size: 14px;
            color: var(--lightGray-color);
            font-family: var(--font-base);

            @media screen and (min-width: 275px) {
              text-transform: uppercase;
              font-size: 12px;
              color: var(--lightGray-color);
              font-family: var(--font-base);
            }
            @media screen and (min-width: 375px) {
              text-transform: uppercase;
              font-size: 12px;
              color: var(--lightGray-color);
              font-family: var(--font-base);
            }
            @media screen and (min-width: 696px) {
              text-transform: uppercase;
              font-size: 12px;
              color: var(--lightGray-color);
              font-family: var(--font-base);
            }
            @media screen and (min-width: 700px) {
              text-transform: uppercase;
              font-size: 12px;
              color: var(--lightGray-color);
              font-family: var(--font-base);
            }
            @media screen and (min-width: 800px) {
              text-transform: uppercase;
              font-size: 12px;
              color: var(--lightGray-color);
              font-family: var(--font-base);
            }
            @media screen and (min-width: 1300px) {
              text-transform: uppercase;
              font-size: 12px;
              color: var(--lightGray-color);
              font-family: var(--font-base);
            }
            @media screen and (min-width: 1420px) {
              text-transform: uppercase;
              font-size: 12px;
              color: var(--lightGray-color);
              font-family: var(--font-base);
            }
            @media screen and (min-width: 1600px) {
              text-transform: uppercase;
              font-size: 14px;
              color: var(--lightGray-color);
              font-family: var(--font-base);
            }

            @media screen and (min-width: 1715px) {
              text-transform: uppercase;
              font-size: 14px;
              color: var(--lightGray-color);
              font-family: var(--font-base);
            }

            @media screen and (min-width: 1900px) {
              text-transform: uppercase;
              font-size: 14px;
              color: var(--lightGray-color);
              font-family: var(--font-base);
            }

            @media screen and (min-width: 2500px) {
              text-transform: uppercase;
              font-size: 14px;
              color: var(--lightGray-color);
              font-family: var(--font-base);
            }

            @media screen and (min-width: 3000px) {
              text-transform: uppercase;
              font-size: 14px;
              color: var(--lightGray-color);
              font-family: var(--font-base);
            }
          }
        }
      }
    }
  }
}
