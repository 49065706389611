.contact__wrapper {
  height: 100%;
  width: 100%;
  margin-bottom: 20rem;

  @media screen and (min-width: 275px) {
    margin-bottom: 5em;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-top: 5rem;
  }
  @media screen and (min-width: 375px) {
    margin-bottom: 5em;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-top: 5rem;
  }
  @media screen and (min-width: 410px) {
    margin-bottom: 5em;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-top: 5rem;
  }
  @media screen and (min-width: 696px) {
    margin-bottom: 5em;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-top: 5rem;
  }
  @media screen and (min-width: 1300px) {
    margin-bottom: 20rem;
    padding-left: 0;
    padding-right: 0;
    margin-top: 0;
  }
  @media screen and (min-width: 1420px) {
    margin-bottom: 20rem;
    padding-left: 0;
    padding-right: 0;
    margin-top: 0;
  }
  @media screen and (min-width: 1600px) {
    margin-bottom: 20rem;
    padding-left: 0;
    padding-right: 0;
    margin-top: 0;
  }
  @media screen and (min-width: 1900px) {
    margin-bottom: 20rem;
    padding-left: 0;
    padding-right: 0;
    margin-top: 0;
  }
  @media screen and (min-width: 3000px) {
    margin-bottom: 20rem;
    padding-left: 48rem;
    padding-right: 48rem;
    margin-top: 0;
  }

  .contactHeader {
    height: 390px;
    width: 100%;
    display: flex;
    align-items: center;
    opacity: 0;

    @media screen and (max-width: 400px) {
      height: 290px;
    }
    @media screen and (max-width: 696px) {
      height: 290px;
    }

    h1 {
      text-transform: uppercase;
      font-weight: 600;
      letter-spacing: 6px;
      font-size: 70px;
      padding-left: 15rem;

      @media screen and (min-width: 270px) {
        text-align: center;
        font-size: 30px;
        padding-left: 0;
        width: 100%;
      }

      @media screen and (min-width: 375px) {
        text-align: center;
        font-size: 30px;
        padding-left: 0;
        width: 100%;
      }

      @media screen and (min-width: 696px) {
        text-align: center;
        font-size: 30px;
        padding-left: 0;
        width: 100%;
      }

      @media screen and (min-width: 1366px) {
        font-size: 55px;
        padding-left: 10rem;
        text-align: left;
        width: none;
      }

      @media screen and (min-width: 1420px) {
        font-size: 60px;
        padding-left: 10rem;
        text-align: left;
        width: none;
      }

      @media screen and (min-width: 1910px) {
        font-size: 70px;
        padding-left: 15rem;
        text-align: left;
        width: none;
      }
    }
  }

  .glitchText__contact {
    position: relative;
    max-width: 1200px;

    @media screen and (max-width: 400px) {
      max-width: 385px;
    }
    @media screen and (max-width: 696px) {
      max-width: 385px;
    }
  }
  .glitchText__contact::before,
  .glitchText__contact::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .glitchText__contact::before {
    left: 2px;
    clip: rect(79px, 1200px, 86px, 0);
    text-shadow: -1px 0 red;
    background: #000;
    animation: brasil-anim-2 1s infinite linear alternate-reverse;
  }

  .glitchText__contact::after {
    /* variation */
    left: -2px;
    clip: rect(79px, 1200px, 86px, 0);
    text-shadow: -1px 0 blue;
    background: #000;
    animation: brasil-anim-1 1s infinite linear alternate-reverse;
    animation-delay: -1s;
  }

  @keyframes brasil-anim-1 {
    0% {
      clip: rect(20px, 1200px, 76px, 0);
    }
    20% {
      clip: rect(19px, 1200px, 16px, 0);
    }
    40% {
      clip: rect(16px, 1200px, 3px, 0);
    }
    60% {
      clip: rect(62px, 1200px, 78px, 0);
    }
    80% {
      clip: rect(25px, 1200px, 13px, 0);
    }
    100% {
      clip: rect(53px, 1200px, 86px, 0);
    }
  }

  @keyframes brasil-anim-2 {
    0% {
      clip: rect(79px, 1200px, 86px, 0);
    }

    20% {
      clip: rect(20px, 1200px, 30px, 0);
    }

    40% {
      clip: rect(25px, 1200px, 5px, 0);
    }

    60% {
      clip: rect(65px, 1200px, 85px, 0);
    }

    80% {
      clip: rect(120px, 1200px, 145px, 0);
    }

    100% {
      clip: rect(95px, 1200px, 75px, 0);
    }
  }

  .btn {
    color: var(--white-color);
    border-bottom: 1px solid var(--secondary-color);
    border-top: none;
    border-left: none;
    border-right: none;
    background-color: #0c0c0c;
    font-family: var(--font-base);
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 2px;
    font-size: 15px;
    z-index: 0;

    @media screen and (min-width: 275px) {
      margin-left: 0;
    }
    @media screen and (min-width: 375px) {
      margin-left: 0;
    }
    @media screen and (min-width: 696px) {
      margin-left: 0;
    }
    @media screen and (min-width: 1300px) {
      margin-left: 2rem;
    }
    @media screen and (min-width: 1420px) {
      margin-left: 2rem;
    }
    @media screen and (min-width: 1600px) {
      margin-left: 0;
    }
    @media screen and (min-width: 1900px) {
      margin-left: 0;
    }
  }

  .btn:hover {
    color: var(--lightGray-color);
  }

  .contact__info {
    grid-area: info;
    padding-bottom: 1rem;

    h3 {
      text-transform: uppercase;
      font-weight: 400;
      letter-spacing: 2px;
      color: var(--secondary-color);
      font-size: 13px;
      position: relative;
      padding-left: 4.5rem;
      padding-bottom: 1rem;

      @media screen and (max-width: 400px) {
        font-size: 12px;
        padding-bottom: 1rem;
      }

      @media screen and (max-width: 696px) {
        font-size: 12px;
        padding-bottom: 1rem;
      }
    }

    h3:before {
      content: '';
      display: block;
      width: 58px;
      height: 1px;
      background: var(--secondary-color);
      left: 1%;
      margin-top: 0.45rem;
      position: absolute;

      @media screen and (max-width: 400px) {
        width: 55px;
      }

      @media screen and (max-width: 696px) {
        width: 55px;
      }
    }

    .contact__details {
      a {
        text-decoration: none;
        color: var(--white-color);
      }

      p {
        text-transform: uppercase;
        font-size: 18px;
        font-family: var(--font-base);
        color: var(--white-color);
        letter-spacing: 2px;
        padding-bottom: 1rem;

        @media screen and (min-width: 270px) {
          font-size: 16px;
        }

        @media screen and (min-width: 375px) {
          font-size: 16px;
        }

        @media screen and (min-width: 696px) {
          font-size: 16px;
        }

        @media screen and (min-width: 1366px) {
          font-size: 18px;
        }

        @media screen and (min-width: 1420px) {
          font-size: 18px;
        }

        @media screen and (min-width: 1910px) {
          font-size: 18px;
        }

        strong {
          color: var(--secondary-color);
        }
      }

      .contact__short-description {
        color: var(--lightGray-color);
        font-family: var(--font-base);
        text-transform: uppercase;
        font-size: 14px;
        max-width: 500px;
        letter-spacing: 2px;

        @media screen and (min-width: 270px) {
          font-size: 13px;
        }

        @media screen and (min-width: 375px) {
          font-size: 13px;
        }

        @media screen and (min-width: 696px) {
          font-size: 13px;
        }

        @media screen and (min-width: 1366px) {
          font-size: 14px;
        }

        @media screen and (min-width: 1440px) {
          font-size: 14px;
        }

        @media screen and (min-width: 1910px) {
          font-size: 14px;
        }
      }
    }
  }

  .socialIcons__container__navBar3 {
    display: flex;
    flex-direction: row;
    padding-left: 10rem;
    padding-top: 2rem;

    @media screen and (min-width: 270px) {
      display: none;
    }

    @media screen and (min-width: 375px) {
      display: none;
    }

    @media screen and (min-width: 696px) {
      display: none;
    }

    @media screen and (min-width: 1366px) {
      padding-left: 10rem;
      display: flex;
    }

    @media screen and (min-width: 1420px) {
      padding-left: 10rem;
      display: flex;
    }

    @media screen and (min-width: 1910px) {
      padding-left: 10rem;
      display: flex;
    }
  }

  ::placeholder {
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 15px;
    font-family: var(--font-base);
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    text-transform: uppercase;
    letter-spacing: 2px;
    font-family: var(--font-base);
    font-size: 15px;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 15px;
    font-family: var(--font-base);
  }
}

.sec_sp2 {
  display: grid;
  grid-template-columns: 35% auto;
  grid-template-rows: 580px;
  grid-template-areas: 'info form';
  width: 100%;
  justify-content: center;

  @media screen and (min-width: 275px) {
    display: flex;
    flex-direction: column;
  }
  @media screen and (min-width: 375px) {
    display: flex;
    flex-direction: column;
  }
  @media screen and (min-width: 696px) {
    display: flex;
    flex-direction: column;
  }
  @media screen and (min-width: 1300px) {
    display: grid;
    flex-direction: none;
  }
  @media screen and (min-width: 1420px) {
    display: grid;
    flex-direction: none;
  }
  @media screen and (min-width: 1600px) {
    display: grid;
    flex-direction: none;
  }
  @media screen and (min-width: 1900px) {
    display: grid;
    flex-direction: none;
  }
  @media screen and (min-width: 3000px) {
    display: grid;
    flex-direction: none;
  }

  .contact__form {
    grid-area: form;
  }

  .contact__form .form-control {
    padding: 1.375rem 0.75rem;
    line-height: 1.5;
    color: var(--white-color);
    background-color: #0c0c0c;
    border-radius: 0 !important;
    border-bottom: 1px solid var(--secondary-color);
    border-top: hidden;
    border-left: hidden;
    border-right: hidden;
    width: 45rem;
    margin-bottom: 2em;
    outline: none;
    font-family: var(--font-base);

    @media screen and (min-width: 275px) {
      width: 100%;
      margin-left: 0;
      margin-bottom: 1em;
    }
    @media screen and (min-width: 375px) {
      margin-bottom: 1em;
      margin-left: 0;
      width: 100%;
    }
    @media screen and (min-width: 696px) {
      margin-bottom: 1em;
      margin-left: 0;
      width: 100%;
    }
    @media screen and (min-width: 1300px) {
      margin-bottom: 2em;
      margin-left: 2rem;
      width: 35rem;
    }
    @media screen and (min-width: 1420px) {
      margin-bottom: 2em;
      width: 35rem;
      margin-left: 2rem;
    }
    @media screen and (min-width: 1600px) {
      margin-bottom: 2em;
      margin-left: 0;
      width: 45rem;
    }
    @media screen and (min-width: 1900px) {
      margin-bottom: 2em;
      margin-left: 0;
      width: 45rem;
    }
  }

  .contact__form .form-control:focus {
    outline: none;
  }

  .contact__form input.form-control {
    margin-bottom: 2em;
    height: calc(2.5em + 0.75rem + 2px);
  }
}

.interestedCheckBox {
  border-bottom: 1px solid var(--secondary-color);
  padding-bottom: 1rem;

  @media screen and (min-width: 275px) {
    margin-left: 0;
  }
  @media screen and (min-width: 375px) {
    margin-left: 0;
  }
  @media screen and (min-width: 696px) {
    margin-left: 0;
  }
  @media screen and (min-width: 1300px) {
    margin-left: 2rem;
  }
  @media screen and (min-width: 1420px) {
    margin-left: 2rem;
  }
  @media screen and (min-width: 1600px) {
    margin-left: 0;
  }
  @media screen and (min-width: 1900px) {
    margin-left: 0;
  }

  h3 {
    padding-bottom: 0.5rem;
    text-transform: uppercase;
    font-size: 15px;
    letter-spacing: 2px;
    font-weight: 500;
  }

  input {
  }
}

.socialIcons__icon__navBar2 {
  margin: 0 5px;
  text-align: center;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
  padding: 8px;

  span {
    position: relative;
    display: block;
    height: 50px;
    // margin-left: 70px;
    z-index: 2;

    svg {
      width: 20px;
      height: 20px;
      color: var(--white-color);
      transition: color 400ms cubic-bezier(0.4, 0, 0.2, 1);
    }

    &:hover {
      svg {
        color: var(--secondary-color);
      }
    }
  }
}

textarea {
  resize: none;
}

.control-group {
  padding-top: 1.5rem;
  padding-bottom: 1rem;

  @media screen and (min-width: 275px) {
    margin-left: 0;
  }
  @media screen and (min-width: 375px) {
    margin-left: 0;
  }
  @media screen and (min-width: 696px) {
    margin-left: 0;
  }
  @media screen and (min-width: 1300px) {
    margin-left: 2rem;
  }
  @media screen and (min-width: 1420px) {
    margin-left: 2rem;
  }
  @media screen and (min-width: 1600px) {
    margin-left: 0;
  }
  @media screen and (min-width: 1900px) {
    margin-left: 0;
  }

  h3 {
    padding-bottom: 1rem;
    text-transform: uppercase;
    font-size: 15px;
    letter-spacing: 2px;
    font-weight: 500;
  }
}

.interestsCB {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cbContainer {
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  background-color: var(--background-color);
  // border-radius: 10px;
  padding: 5px;
}

.cbContainer div {
  margin: 5px;
}

.cbContainer div label {
  cursor: pointer;
}

.cbContainer div label input[type='checkbox'] {
  display: none;
}

.cbContainer div label span {
  position: relative;
  display: inline-block;
  background: var(--gray-color);
  padding: 10px 10px;
  color: (--white-color);
  // border-radius: 30px;
  transition: 0.5s;
  user-select: none;
  overflow: hidden;
  text-transform: uppercase;
  font-family: var(--font-base);
  letter-spacing: 2px;
  font-size: 14px;
}

.cbContainer div label input[type='checkbox']:checked ~ span {
  background: var(--secondary-color);
  color: white;
}

.form__wrapper {
  .contact__requiredText {
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 2px;
    font-family: var(--font-base);
    color: var(--lightGray-color);
    padding-bottom: 1rem;

    @media screen and (min-width: 275px) {
      width: 100%;
      margin-left: 0;
      font-size: 11px;
      text-align: center;
    }
    @media screen and (min-width: 375px) {
      margin-left: 0;
      width: 100%;
      font-size: 11px;
      text-align: center;
    }
    @media screen and (min-width: 696px) {
      margin-left: 0;
      width: 100%;
      font-size: 11px;
      text-align: center;
    }
    @media screen and (min-width: 1300px) {
      margin-left: 2rem;
      width: 35rem;
      font-size: 13px;
      text-align: left;
    }
    @media screen and (min-width: 1420px) {
      width: 35rem;
      margin-left: 2rem;
      font-size: 13px;
      text-align: left;
    }
    @media screen and (min-width: 1600px) {
      margin-left: 0;
      width: 45rem;
      font-size: 13px;
      text-align: left;
    }
    @media screen and (min-width: 1900px) {
      margin-left: 0;
      width: 45rem;
      font-size: 13px;
      text-align: left;
    }

    .required__symbol {
      color: red;
    }
  }
}

.vehicle-date__wrapper {
  display: grid;
  grid-template-columns: 40% auto;

  @media screen and (max-width: 400px) {
    grid-template-columns: 1fr;
  }
  @media screen and (max-width: 696px) {
    grid-template-columns: 1fr;
  }

  .select {
    position: relative;
    display: inline-block;
    margin-bottom: 15px;
    width: 60%;

    @media screen and (max-width: 400px) {
      width: 100%;
    }
    @media screen and (max-width: 696px) {
      width: 100%;
    }
  }
  .select select {
    display: inline-block;
    width: 100%;
    cursor: pointer;
    padding: 10px 15px;
    outline: 0;
    border: 0;
    border-radius: 0 !important;
    background: #0c0c0c;
    color: var(--lightGray-color);
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    font-family: var(--font-base);
    font-size: 15px;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-bottom: 1px solid var(--secondary-color);
  }
  .select select::-ms-expand {
    display: none;
  }
  .select select:hover,
  .select select:focus {
    color: var(--lightGray-color);
    background: #1a1a1a;
  }
  .select__arrow {
    position: absolute;
    top: 16px;
    right: 15px;
    width: 0;
    height: 0;
    pointer-events: none;
    border-style: solid;
    border-width: 8px 5px 0 5px;
    border-color: #7b7b7b transparent transparent transparent;
  }
  .select select:hover ~ .select__arrow,
  .select select:focus ~ .select__arrow {
    border-top-color: #000;
  }

  .date__wrapper {
    @media screen and (max-width: 400px) {
      margin-bottom: 1rem;
    }
    @media screen and (max-width: 696px) {
      margin-bottom: 1rem;
    }

    .datePick {
      background-color: #0c0c0c;
      border: none;
      border-bottom: 1px solid var(--secondary-color);
      color: var(--lightGray-color);
      width: 70%;
      padding: 10px 15px;
      height: 2.5rem;
      font-family: var(--font-base);
      font-size: 15px;
      text-transform: uppercase;
      letter-spacing: 1px;
      cursor: pointer;
      outline: none;

      @media screen and (max-width: 400px) {
        width: 100%;
      }
      @media screen and (max-width: 696px) {
        width: 100%;
      }
    }

    .datePick:hover,
    .datePick:focus {
      color: var(--lightGray-color);
      background: #1a1a1a;
    }
  }

  .calenderStyle {
    background: #0c0c0c;
    border-color: var(--secondary-color);
    border-radius: 0;
    // color: red;
    overflow-x: hidden;
    overflow-y: hidden;
  }

  .react-datepicker__header {
    background: #242424;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header,
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    color: white;
    font-family: var(--font-base);
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--disabled {
    color: rgb(124, 124, 124);
    font-family: var(--font-base);
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .react-datepicker__time-container .react-datepicker__time {
    background: #0c0c0c;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list {
    color: white;
    font-family: var(--font-base);
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item:hover {
    background: var(--gray-color);
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: var(--secondary-color);
    color: white;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    border-radius: 0.3rem;
    background-color: var(--secondary-color);
    color: #fff;
  }

  .dayStyle {
    color: white;
  }

  .dayStyle:hover {
    background: var(--gray-color);
  }

  // .timeStyle {
  //   color: white;
  //   background: #0c0c0c;
  // }

  .timeStyle:hover {
    // color: var(--secondary-color);
    background: var(--gray-color);
  }
}

.contact__details {
  @media screen and (max-width: 400px) {
    display: none;
  }

  @media screen and (max-width: 696px) {
    display: none;
  }
}

span.anchor {
  display: block;
  position: relative;
  top: -250px;
  visibility: hidden;
}

.circle-loader {
  margin-bottom: -2rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-left-color: var(--secondary-color);
  animation: loader-spin 1.2s infinite linear;
  position: relative;
  display: flex;
  border-radius: 50%;
  width: 7em;
  height: 7em;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;

  @media screen and (max-width: 400px) {
    margin-bottom: 2rem;
    margin-top: 1rem;
  }

  @media screen and (max-width: 696px) {
    margin-bottom: 2rem;
    margin-top: 1rem;
  }
}

.load-complete {
  -webkit-animation: none;
  animation: none;
  border-color: var(--secondary-color);
  transition: border 500ms ease-out;
}

.checkmark {
}
.checkmark.draw:after {
  animation-duration: 1000ms;
  animation-timing-function: ease;
  animation-name: checkmark;
  transform: scaleX(-1) rotate(135deg);
}
.checkmark:after {
  opacity: 1;
  height: 3.5em;
  width: 1.75em;
  transform-origin: left top;
  border-right: 3px solid var(--secondary-color);
  border-top: 3px solid var(--secondary-color);
  content: '';
  left: 1.75em;
  top: 3.5em;
  position: absolute;
}

@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 0;
  }
  20% {
    height: 0;
    width: 1.75em;
    opacity: 1;
  }
  40% {
    height: 3.5em;
    width: 1.75em;
    opacity: 1;
  }
  100% {
    height: 3.5em;
    width: 1.75em;
    opacity: 1;
  }
}
