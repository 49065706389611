@mixin black-gradient {
  background: linear-gradient(to right, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
}

$animationSpeed: 40s;

// Animation
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7));
  }
}

// Styling
.slider__wrapper {
  margin-bottom: 5rem;
  margin-top: 10rem;

  .slider__heading {
    margin-bottom: 3rem;

    @media screen and (min-width: 270px) {
      padding-left: 2rem;
      padding-right: 2rem;
    }

    @media screen and (min-width: 375px) {
      padding-left: 2rem;
      padding-right: 2rem;
    }

    @media screen and (min-width: 696px) {
      padding-left: 2rem;
      padding-right: 2rem;
    }

    @media screen and (min-width: 1366px) {
      padding-left: 0;
      padding-right: 0;
    }

    @media screen and (min-width: 1440px) {
      padding-left: 0;
      padding-right: 0;
    }

    @media screen and (min-width: 1910px) {
      padding-left: 0;
      padding-right: 0;
    }

    h1 {
      text-transform: uppercase;
      letter-spacing: 4px;
      font-size: 45px;
      font-weight: 600;
      font-family: var(--font-base);
      padding-bottom: 1rem;
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
      text-align: center;

      @media screen and (min-width: 270px) {
        font-size: 30px;
        max-width: 100%;
      }

      @media screen and (min-width: 375px) {
        font-size: 30px;
        max-width: 100%;
      }

      @media screen and (min-width: 696px) {
        font-size: 30px;
        max-width: 100%;
      }

      @media screen and (min-width: 1366px) {
        font-size: 45px;
        max-width: 600px;
      }

      @media screen and (min-width: 1440px) {
        font-size: 45px;
        max-width: 600px;
      }

      @media screen and (min-width: 1910px) {
        font-size: 45px;
        max-width: 600px;
      }
    }

    .slider__divider {
      width: 30%;
      margin-left: auto;
      margin-right: auto;
      height: 1px;
      background-color: var(--secondary-color);
      border: none;

      @media screen and (min-width: 270px) {
        width: 100%;
      }

      @media screen and (min-width: 375px) {
        width: 100%;
      }

      @media screen and (min-width: 696px) {
        width: 100%;
      }

      @media screen and (min-width: 1366px) {
        width: 30%;
      }

      @media screen and (min-width: 1440px) {
        width: 30%;
      }

      @media screen and (min-width: 1910px) {
        width: 30%;
      }
    }

    p {
      text-align: center;
      padding-top: 1rem;
      text-transform: uppercase;
      font-size: 14px;
      color: var(--lightGray-color);
      font-family: var(--font-base);
      letter-spacing: 1px;
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;

      @media screen and (min-width: 270px) {
        font-size: 12px;
      }

      @media screen and (min-width: 375px) {
        font-size: 12px;
      }

      @media screen and (min-width: 696px) {
        font-size: 12px;
      }

      @media screen and (min-width: 1366px) {
        font-size: 14px;
      }

      @media screen and (min-width: 1440px) {
        font-size: 14px;
      }

      @media screen and (min-width: 1910px) {
        font-size: 14px;
      }
    }
  }

  .slider__partners {
    background: var(--background-color);
    box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125);
    height: 100px;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 960px;

    @media screen and (max-width: 400px) {
      width: 100%;
    }
    @media screen and (max-width: 696px) {
      width: 100%;
    }

    &::before,
    &::after {
      @include black-gradient;
      content: '';
      height: 100px;
      position: absolute;
      width: 200px;
      z-index: 2;
    }

    &::after {
      right: 0;
      top: 0;
      transform: rotateZ(180deg);
    }

    &::before {
      left: 0;
      top: 0;
    }

    .slide-track {
      animation: scroll $animationSpeed linear infinite;
      display: flex;
      width: calc(250px * 14);
    }

    .slide {
      height: 100px;
      width: 250px;
    }
  }
}
