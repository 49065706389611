.tintsPercentage__wrapper {
  width: 100%;
  height: 100%;
  margin-bottom: 5rem;
  padding-left: 200px;
  padding-right: 200px;

  @media screen and (min-width: 275px) {
    padding-left: 2rem;
    padding-right: 2rem;
    margin-bottom: 5rem;
  }
  @media screen and (min-width: 375px) {
    padding-left: 2rem;
    padding-right: 2rem;
    margin-bottom: 5rem;
  }
  @media screen and (min-width: 696px) {
    padding-left: 2rem;
    padding-right: 2rem;
    margin-bottom: 5rem;
  }
  @media screen and (min-width: 1300px) {
    padding-left: 200px;
    padding-right: 200px;
    margin-bottom: 5rem;
  }
  @media screen and (min-width: 1420px) {
    padding-left: 200px;
    padding-right: 200px;
    margin-bottom: 5rem;
  }
  @media screen and (min-width: 1600px) {
    padding-left: 200px;
    padding-right: 200px;
    margin-bottom: 5rem;
  }
  @media screen and (min-width: 1900px) {
    padding-left: 200px;
    padding-right: 200px;
    margin-bottom: 5rem;
  }
  @media screen and (min-width: 3000px) {
    padding-left: 48rem;
    padding-right: 48rem;
    margin-bottom: 5rem;
  }

  .tintsPercentage__title {
    text-transform: uppercase;
    letter-spacing: 4px;
    font-size: 45px;
    font-weight: 600;
    font-family: var(--font-base);
    padding-bottom: 1rem;
    max-width: 500px;
  }

  .tintsPercentage__grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 380px;
    grid-template-areas: 'grid1 grid2 grid3 grid4';

    background: url('../../assets/bd/bd-47.jpg');
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;

    .tintsPercentage__grid1 {
      grid-area: grid1;
      background: rgba(12, 12, 12, 0.9);
      display: flex;
      justify-content: center;
      align-items: center;

      h1 {
        text-transform: uppercase;
      }
    }

    .tintsPercentage__grid2 {
      grid-area: grid2;
      background: rgba(20, 20, 20, 0.9);
      display: flex;
      justify-content: center;
      align-items: center;

      h1 {
        text-transform: uppercase;
      }
    }

    .tintsPercentage__grid3 {
      grid-area: grid3;
      background: rgba(29, 29, 29, 0.9);
      display: flex;
      justify-content: center;
      align-items: center;

      h1 {
        text-transform: uppercase;
      }
    }

    .tintsPercentage__grid4 {
      grid-area: grid4;
      background: rgba(39, 39, 39, 0.9);
      display: flex;
      justify-content: center;
      align-items: center;

      h1 {
        text-transform: uppercase;
      }
    }
  }

  .tintsPercentage__table-container {
    // padding: 0 10%;
    margin: 40px auto 0;

    .tintsPercentage__table-container__table {
      width: 100%;
      border-collapse: collapse;

      thead {
        background-color: #181818;

        tr th {
          font-size: 14px;
          font-weight: 600;
          letter-spacing: 3px;
          text-transform: uppercase;
          color: var(--secondary-color);
          opacity: 1;
          padding: 12px;
          vertical-align: top;
          border: 1px solid #1a1a1a;
          width: 100px;
        }
      }

      tbody {
        tr td {
          font-size: 14px;
          letter-spacing: 2px;
          text-transform: uppercase;
          font-weight: 500;
          color: white;
          background-color: #0c0c0c;
          padding: 8px;
          text-align: center;
          border: 1px solid #1a1a1a;

          @media screen and (max-width: 768px) {
            text-align: right;
            padding-left: 50%;
            position: relative;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .tintsPercentage__table-container__table thead {
    display: none;
  }
  .tintsPercentage__table-container__table,
  .tintsPercentage__table-container__table tbody,
  .tintsPercentage__table-container__table tr,
  .tintsPercentage__table-container__table td {
    display: block;
    width: 100%;
  }
  .tintsPercentage__table-container__table tr {
    margin-bottom: 15px;
  }
  .tintsPercentage__table-container__table td:before {
    content: attr(data-label);
    position: absolute;
    left: 0;
    width: 60%;
    padding-left: 15px;
    font-weight: 600;
    font-size: 13px;
    text-align: left;
    color: var(--secondary-color);
  }
}
