.homeProcess__section {
  margin-top: 5%;
  width: 100%;

  @media screen and (max-width: 400px) {
    margin-top: -40%;
    margin-bottom: 20%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  @media screen and (max-width: 696px) {
    margin-top: -40%;
    margin-bottom: 20%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .homeProcess__heading {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    opacity: 0;

    h3 {
      text-transform: uppercase;
      font-weight: 400;
      letter-spacing: 2px;
      color: var(--secondary-color);
      font-size: 18px;
      position: relative;
    }

    h3:before {
      content: '';
      display: block;
      width: 70px;
      height: 1px;
      background: var(--secondary-color);
      left: -52%;
      margin-top: 0.6rem;
      position: absolute;

      @media screen and (max-width: 400px) {
        width: 50px;
        left: -38%;
      }
      @media screen and (max-width: 696px) {
        width: 50px;
        left: -38%;
      }
    }

    h3:after {
      content: '';
      display: block;
      width: 70px;
      height: 1px;
      background: var(--secondary-color);
      right: -52%;
      margin-top: -0.8rem;
      position: absolute;

      @media screen and (max-width: 400px) {
        width: 50px;
        right: -38%;
      }
      @media screen and (max-width: 696px) {
        width: 50px;
        right: -38%;
      }
    }

    h1 {
      text-transform: uppercase;
      letter-spacing: 3px;
      font-weight: 600;
      font-size: 60px;
      width: 429px;
      padding-top: 1%;
      text-align: center;

      @media screen and (min-width: 275px) {
        font-size: 30px;
        padding-top: 4%;
        width: 100%;
      }
      @media screen and (min-width: 375px) {
        font-size: 38px;
        padding-top: 4%;
        width: 100%;
      }
      @media screen and (min-width: 696px) {
        font-size: 38px;
        padding-top: 4%;
        width: 100%;
      }
      @media screen and (min-width: 1300px) {
        font-size: 60px;
        padding-top: 1%;
        width: 429px;
      }
      @media screen and (min-width: 1420px) {
        font-size: 60px;
        padding-top: 1%;
        width: 429px;
      }
      @media screen and (min-width: 1600px) {
        font-size: 60px;
        padding-top: 1%;
        width: 429px;
      }

      @media screen and (min-width: 1715px) {
        font-size: 60px;
        padding-top: 1%;
        width: 429px;
      }

      @media screen and (min-width: 1900px) {
        font-size: 60px;
        padding-top: 1%;
        width: 429px;
      }

      @media screen and (min-width: 2500px) {
        font-size: 60px;
        padding-top: 1%;
        width: 429px;
      }

      @media screen and (min-width: 3000px) {
        font-size: 60px;
        padding-top: 1%;
        width: 429px;
      }
    }

    p {
      text-transform: uppercase;
      width: 700px;
      text-align: center;
      padding-top: 1%;
      color: var(--lightGray-color);
      font-family: var(--font-base);

      @media screen and (max-width: 400px) {
        width: 100%;
        padding-top: 8%;
        padding-left: 4%;
        padding-right: 4%;
        font-size: 13px;
      }
      @media screen and (max-width: 696px) {
        width: 100%;
        padding-top: 8%;
        padding-left: 4%;
        padding-right: 4%;
        font-size: 13px;
      }
    }
  }

  .homeProcess__items {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    min-height: 380px;
    flex-direction: row;
    padding-left: 12rem;
    padding-right: 12rem;
    width: 100%;
    margin-top: -2%;
    opacity: 0;

    @media screen and (max-width: 400px) {
      flex-direction: column;
      margin-top: 10%;
      padding-left: 0;
      padding-right: 0;
    }
    @media screen and (max-width: 696px) {
      flex-direction: column;
      margin-top: 10%;
      padding-left: 0;
      padding-right: 0;
    }
    @media screen and (min-width: 2540px) {
      padding-left: 30rem;
      padding-right: 30rem;
    }
    @media screen and (min-width: 3000px) {
      padding-left: 60rem;
      padding-right: 60rem;
    }

    .homeProcess__item1 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;

      h1 {
        color: var(--white-color);
        text-transform: uppercase;
        letter-spacing: 3px;
        text-align: center;
        font-size: 17px;
        width: 255px;
        padding-top: 10%;
      }

      .homeProcess__processOne__icon {
        width: 60px;
        height: auto;

        filter: invert(50%) sepia(49%) saturate(3039%) hue-rotate(5deg)
          brightness(98%) contrast(101%);
      }

      @media screen and (max-width: 400px) {
        padding-bottom: 45px;
      }
      @media screen and (max-width: 696px) {
        padding-bottom: 45px;
      }
    }

    .homeProcess__item1:after {
      content: '';
      display: block;
      width: 16rem;
      height: 1px;
      right: -69%;
      margin-top: -3.5rem;
      position: absolute;
      border: none;
      border-top: 2px dotted var(--white-color);

      @media screen and (min-width: 275px) {
        display: none;
        width: 16rem;
        right: -69%;
      }
      @media screen and (min-width: 375px) {
        display: none;
        width: 16rem;
        right: -69%;
      }
      @media screen and (min-width: 696px) {
        display: none;
        width: 16rem;
        right: -69%;
      }
      @media screen and (min-width: 1300px) {
        display: block;
        width: 11rem;
        right: -35%;
      }
      @media screen and (min-width: 1420px) {
        display: block;
        width: 11rem;
        right: -35%;
      }
      @media screen and (min-width: 1600px) {
        display: block;
        width: 14rem;
        right: -56%;
      }

      @media screen and (min-width: 1715px) {
        display: block;
        width: 14rem;
        right: -56%;
      }

      @media screen and (min-width: 1900px) {
        display: block;
        width: 16rem;
        right: -69%;
      }

      @media screen and (min-width: 2500px) {
        display: block;
        width: 16rem;
        right: -69%;
      }

      @media screen and (min-width: 3000px) {
        display: block;
        width: 16rem;
        right: -69%;
      }
    }

    .homeProcess__item2 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;

      h1 {
        color: var(--white-color);
        text-transform: uppercase;
        letter-spacing: 3px;
        text-align: center;
        font-size: 17px;
        width: 255px;
        padding-top: 10%;
      }

      .homeProcess__processTwo {
        width: 60px;
        height: auto;

        filter: invert(50%) sepia(49%) saturate(3039%) hue-rotate(5deg)
          brightness(98%) contrast(101%);
      }

      @media screen and (max-width: 400px) {
        padding-bottom: 45px;
      }
      @media screen and (max-width: 696px) {
        padding-bottom: 45px;
      }
    }

    .homeProcess__item2:after {
      content: '';
      display: block;
      width: 16rem;
      height: 1px;
      right: -69%;
      margin-top: -3.5rem;
      position: absolute;
      border: none;
      border-top: 2px dotted var(--white-color);

      @media screen and (min-width: 275px) {
        display: none;
        width: 16rem;
        right: -69%;
      }
      @media screen and (min-width: 375px) {
        display: none;
        width: 16rem;
        right: -69%;
      }
      @media screen and (min-width: 696px) {
        display: none;
        width: 16rem;
        right: -69%;
      }
      @media screen and (min-width: 1300px) {
        display: block;
        width: 11rem;
        right: -35%;
      }
      @media screen and (min-width: 1420px) {
        display: block;
        width: 11rem;
        right: -35%;
      }
      @media screen and (min-width: 1600px) {
        display: block;
        width: 14rem;
        right: -56%;
      }

      @media screen and (min-width: 1715px) {
        display: block;
        width: 14rem;
        right: -56%;
      }

      @media screen and (min-width: 1900px) {
        display: block;
        width: 16rem;
        right: -69%;
      }

      @media screen and (min-width: 2500px) {
        display: block;
        width: 16rem;
        right: -69%;
      }

      @media screen and (min-width: 3000px) {
        display: block;
        width: 16rem;
        right: -69%;
      }
    }

    .homeProcess__item3 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;

      h1 {
        color: var(--white-color);
        text-transform: uppercase;
        letter-spacing: 3px;
        text-align: center;
        font-size: 17px;
        width: 255px;
        padding-top: 10%;
      }

      .homeProcess__processThree {
        width: 60px;
        height: auto;

        filter: invert(50%) sepia(49%) saturate(3039%) hue-rotate(5deg)
          brightness(98%) contrast(101%);
      }

      @media screen and (max-width: 400px) {
        padding-bottom: 45px;
      }
      @media screen and (max-width: 696px) {
        padding-bottom: 45px;
      }
    }

    .homeProcess__item3:after {
      content: '';
      display: block;
      width: 16rem;
      height: 1px;
      right: -69%;
      margin-top: -3.5rem;
      position: absolute;
      border: none;
      border-top: 2px dotted var(--white-color);

      @media screen and (min-width: 275px) {
        display: none;
        width: 16rem;
        right: -69%;
      }
      @media screen and (min-width: 375px) {
        display: none;
        width: 16rem;
        right: -69%;
      }
      @media screen and (min-width: 696px) {
        display: none;
        width: 16rem;
        right: -69%;
      }
      @media screen and (min-width: 1300px) {
        display: block;
        width: 11rem;
        right: -35%;
      }
      @media screen and (min-width: 1420px) {
        display: block;
        width: 11rem;
        right: -35%;
      }
      @media screen and (min-width: 1600px) {
        display: block;
        width: 14rem;
        right: -56%;
      }

      @media screen and (min-width: 1715px) {
        display: block;
        width: 14rem;
        right: -56%;
      }

      @media screen and (min-width: 1900px) {
        display: block;
        width: 16rem;
        right: -69%;
      }

      @media screen and (min-width: 2500px) {
        display: block;
        width: 16rem;
        right: -69%;
      }

      @media screen and (min-width: 3000px) {
        display: block;
        width: 16rem;
        right: -69%;
      }
    }

    .homeProcess__item4 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h1 {
        color: var(--white-color);
        text-transform: uppercase;
        letter-spacing: 3px;
        text-align: center;
        font-size: 17px;
        width: 255px;
        padding-top: 10%;
      }

      .homeProcess__processFour {
        width: 60px;
        height: auto;

        filter: invert(50%) sepia(49%) saturate(3039%) hue-rotate(5deg)
          brightness(98%) contrast(101%);
      }
    }
  }
}
