.tintsPackages__wrapper {
  width: 100%;
  height: 100%;
  padding-left: 200px;
  padding-right: 200px;
  margin-bottom: 10rem;
  opacity: 0;

  @media screen and (min-width: 275px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  @media screen and (min-width: 375px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  @media screen and (min-width: 696px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  @media screen and (min-width: 1300px) {
    padding-left: 200px;
    padding-right: 200px;
  }
  @media screen and (min-width: 1420px) {
    padding-left: 200px;
    padding-right: 200px;
  }
  @media screen and (min-width: 1600px) {
    padding-left: 200px;
    padding-right: 200px;
  }
  @media screen and (min-width: 1900px) {
    padding-left: 200px;
    padding-right: 200px;
  }
  @media screen and (min-width: 3000px) {
    padding-left: 52rem;
    padding-right: 52rem;
  }

  .tintsPackages__grid {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media screen and (min-width: 275px) {
      flex-direction: column;
    }
    @media screen and (min-width: 375px) {
      flex-direction: column;
    }
    @media screen and (min-width: 696px) {
      flex-direction: column;
    }
    @media screen and (min-width: 1300px) {
      flex-direction: row;
    }
    @media screen and (min-width: 1420px) {
      flex-direction: row;
    }
    @media screen and (min-width: 1600px) {
      flex-direction: row;
    }
    @media screen and (min-width: 1900px) {
      flex-direction: row;
    }

    .tintsPackages__grid1 {
      background-color: #0c0c0c;
      width: 440px;
      height: 300px;
      padding: 40px;
      position: relative;

      @media screen and (min-width: 275px) {
        width: 100%;
      }
      @media screen and (min-width: 375px) {
        width: 100%;
      }
      @media screen and (min-width: 696px) {
        width: 100%;
      }
      @media screen and (min-width: 1300px) {
        width: 440px;
      }
      @media screen and (min-width: 1420px) {
        width: 440px;
      }
      @media screen and (min-width: 1600px) {
        width: 440px;
      }
      @media screen and (min-width: 1900px) {
        width: 440px;
      }

      .tintsPackages__dollarSign {
        font-family: var(--font-base);
        font-weight: 600;
        text-transform: uppercase;
        font-size: 23px;
        letter-spacing: 3px;
        vertical-align: top;
        padding-right: 0.2rem;
      }

      .tintsPackages__grid1__price {
        font-family: var(--font-base);
        font-weight: 600;
        text-transform: uppercase;
        font-size: 35px;
        letter-spacing: 3px;
      }

      .tintsPackages__grid1__description {
        font-family: var(--font-base);
        font-weight: 500;
        text-transform: uppercase;
        font-size: 25px;
        letter-spacing: 3px;
        padding-top: 1rem;
      }

      .arrow-button a {
        font-size: 14px;
        line-height: 1.9;
        text-decoration: none;
        color: var(--secondary-color);
        font-family: var(--font-base);
        transition: all 0.2s linear;
        text-transform: uppercase;
        letter-spacing: 1px;
        display: flex;
        justify-content: flex-start;
        -moz-transition: all 0.2s linear;
        -webkit-transition: all 0.2s linear;
        -o-transition: all 0.2s linear;
        position: absolute;
        left: 2.5rem;
        bottom: 1.5rem;

        &:hover {
          color: #d35100;
        }
        &::after {
          padding-left: 6px;
          content: url(https://addsomebrown.com/assets/img/arrow_right_black.svg);
          display: inline-block;
          transition: all 0.2s linear;
          -moz-transition: all 0.2s linear;
          -webkit-transition: all 0.2s linear;
          -o-transition: all 0.2s linear;
          filter: invert(47%) sepia(48%) saturate(2923%) hue-rotate(9deg)
            brightness(101%) contrast(105%);
        }
        &:hover::after {
          padding-left: 6px;
          content: url(https://addsomebrown.com/assets/img/arrow_right_black.svg);
          display: inline-block;
          padding-left: 12px;
          filter: invert(47%) sepia(45%) saturate(7481%) hue-rotate(11deg)
            brightness(90%) contrast(102%);
        }

        @media screen and (max-width: 400px) {
          padding-top: 2rem;
        }
        @media screen and (max-width: 696px) {
          padding-top: 2rem;
        }
      }
    }

    .tintsPackages__grid2 {
      background-color: var(--secondary-color);
      width: 440px;
      height: 300px;
      padding: 40px;
      position: relative;

      @media screen and (min-width: 275px) {
        width: 100%;
      }
      @media screen and (min-width: 375px) {
        width: 100%;
      }
      @media screen and (min-width: 696px) {
        width: 100%;
      }
      @media screen and (min-width: 1300px) {
        width: 440px;
      }
      @media screen and (min-width: 1420px) {
        width: 440px;
      }
      @media screen and (min-width: 1600px) {
        width: 440px;
      }
      @media screen and (min-width: 1900px) {
        width: 440px;
      }

      .tintsPackages__dollarSign {
        font-family: var(--font-base);
        font-weight: 600;
        text-transform: uppercase;
        font-size: 23px;
        letter-spacing: 3px;
        vertical-align: top;
        padding-right: 0.2rem;
      }

      .tintsPackages__grid2__price {
        font-family: var(--font-base);
        font-weight: 600;
        text-transform: uppercase;
        font-size: 35px;
        letter-spacing: 3px;
        color: black;
      }

      .tintsPackages__grid2__description {
        font-family: var(--font-base);
        font-weight: 500;
        text-transform: uppercase;
        font-size: 25px;
        letter-spacing: 3px;
        padding-top: 1rem;
        color: black;
      }

      .arrow-button2 a {
        font-size: 14px;
        line-height: 1.9;
        text-decoration: none;
        color: black;
        font-family: var(--font-base);
        transition: all 0.2s linear;
        text-transform: uppercase;
        letter-spacing: 1px;
        display: flex;
        justify-content: flex-start;
        -moz-transition: all 0.2s linear;
        -webkit-transition: all 0.2s linear;
        -o-transition: all 0.2s linear;
        position: absolute;
        left: 2.5rem;
        bottom: 1.5rem;

        &:hover {
          color: black;
        }
        &::after {
          padding-left: 6px;
          content: url(https://addsomebrown.com/assets/img/arrow_right_black.svg);
          display: inline-block;
          transition: all 0.2s linear;
          -moz-transition: all 0.2s linear;
          -webkit-transition: all 0.2s linear;
          -o-transition: all 0.2s linear;
          filter: invert(0%) sepia(100%) saturate(8%) hue-rotate(159deg)
            brightness(99%) contrast(103%);
        }
        &:hover::after {
          padding-left: 6px;
          content: url(https://addsomebrown.com/assets/img/arrow_right_black.svg);
          display: inline-block;
          padding-left: 12px;
          filter: invert(0%) sepia(100%) saturate(8%) hue-rotate(159deg)
            brightness(99%) contrast(103%);
        }

        @media screen and (max-width: 400px) {
          padding-top: 2rem;
        }
        @media screen and (max-width: 696px) {
          padding-top: 2rem;
        }
      }
    }

    .tintsPackages__grid3 {
      background-color: #0c0c0c;
      width: 440px;
      height: 300px;
      padding: 40px;
      position: relative;

      @media screen and (min-width: 275px) {
        width: 100%;
      }
      @media screen and (min-width: 375px) {
        width: 100%;
      }
      @media screen and (min-width: 696px) {
        width: 100%;
      }
      @media screen and (min-width: 1300px) {
        width: 440px;
      }
      @media screen and (min-width: 1420px) {
        width: 440px;
      }
      @media screen and (min-width: 1600px) {
        width: 440px;
      }
      @media screen and (min-width: 1900px) {
        width: 440px;
      }

      .tintsPackages__dollarSign {
        font-family: var(--font-base);
        font-weight: 600;
        text-transform: uppercase;
        font-size: 23px;
        letter-spacing: 3px;
        vertical-align: top;
        padding-right: 0.2rem;
      }

      .tintsPackages__startingAt {
        font-family: var(--font-base);
        font-weight: 600;
        text-transform: uppercase;
        font-size: 15px;
        letter-spacing: 3px;
        vertical-align: top;
      }

      .tintsPackages__grid3__price {
        font-family: var(--font-base);
        font-weight: 600;
        text-transform: uppercase;
        font-size: 35px;
        letter-spacing: 3px;
      }

      .tintsPackages__grid3__description {
        font-family: var(--font-base);
        font-weight: 500;
        text-transform: uppercase;
        font-size: 25px;
        letter-spacing: 3px;
        padding-top: 1rem;
      }

      .arrow-button3 a {
        font-size: 14px;
        line-height: 1.9;
        text-decoration: none;
        color: var(--secondary-color);
        font-family: var(--font-base);
        transition: all 0.2s linear;
        text-transform: uppercase;
        letter-spacing: 1px;
        display: flex;
        justify-content: flex-start;
        -moz-transition: all 0.2s linear;
        -webkit-transition: all 0.2s linear;
        -o-transition: all 0.2s linear;
        position: absolute;
        left: 2.5rem;
        bottom: 1.5rem;

        &:hover {
          color: #d35100;
        }
        &::after {
          padding-left: 6px;
          content: url(https://addsomebrown.com/assets/img/arrow_right_black.svg);
          display: inline-block;
          transition: all 0.2s linear;
          -moz-transition: all 0.2s linear;
          -webkit-transition: all 0.2s linear;
          -o-transition: all 0.2s linear;
          filter: invert(47%) sepia(48%) saturate(2923%) hue-rotate(9deg)
            brightness(101%) contrast(105%);
        }
        &:hover::after {
          padding-left: 6px;
          content: url(https://addsomebrown.com/assets/img/arrow_right_black.svg);
          display: inline-block;
          padding-left: 12px;
          filter: invert(47%) sepia(45%) saturate(7481%) hue-rotate(11deg)
            brightness(90%) contrast(102%);
        }

        @media screen and (max-width: 400px) {
          padding-top: 2rem;
        }
        @media screen and (max-width: 696px) {
          padding-top: 2rem;
        }
      }
    }

    .tintsPackages__grid4 {
      background-color: var(--secondary-color);
      width: 440px;
      height: 300px;
      padding: 40px;
      position: relative;

      @media screen and (min-width: 275px) {
        width: 100%;
      }
      @media screen and (min-width: 375px) {
        width: 100%;
      }
      @media screen and (min-width: 696px) {
        width: 100%;
      }
      @media screen and (min-width: 1300px) {
        width: 440px;
      }
      @media screen and (min-width: 1420px) {
        width: 440px;
      }
      @media screen and (min-width: 1600px) {
        width: 440px;
      }
      @media screen and (min-width: 1900px) {
        width: 440px;
      }

      .tintsPackages__dollarSign {
        font-family: var(--font-base);
        font-weight: 600;
        text-transform: uppercase;
        font-size: 23px;
        letter-spacing: 3px;
        vertical-align: top;
        padding-right: 0.2rem;
      }

      .tintsPackages__grid4__price {
        font-family: var(--font-base);
        font-weight: 600;
        text-transform: uppercase;
        font-size: 35px;
        letter-spacing: 3px;
        color: black;
      }

      .tintsPackages__grid4__description {
        font-family: var(--font-base);
        font-weight: 500;
        text-transform: uppercase;
        font-size: 25px;
        letter-spacing: 3px;
        padding-top: 1rem;
        color: black;
      }

      .arrow-button4 a {
        font-size: 14px;
        line-height: 1.9;
        text-decoration: none;
        color: black;
        font-family: var(--font-base);
        transition: all 0.2s linear;
        text-transform: uppercase;
        letter-spacing: 1px;
        display: flex;
        justify-content: flex-start;
        -moz-transition: all 0.2s linear;
        -webkit-transition: all 0.2s linear;
        -o-transition: all 0.2s linear;
        position: absolute;
        left: 2.5rem;
        bottom: 1.5rem;

        &:hover {
          color: black;
        }
        &::after {
          padding-left: 6px;
          content: url(https://addsomebrown.com/assets/img/arrow_right_black.svg);
          display: inline-block;
          transition: all 0.2s linear;
          -moz-transition: all 0.2s linear;
          -webkit-transition: all 0.2s linear;
          -o-transition: all 0.2s linear;
          filter: invert(0%) sepia(100%) saturate(8%) hue-rotate(159deg)
            brightness(99%) contrast(103%);
        }
        &:hover::after {
          padding-left: 6px;
          content: url(https://addsomebrown.com/assets/img/arrow_right_black.svg);
          display: inline-block;
          padding-left: 12px;
          filter: invert(0%) sepia(100%) saturate(8%) hue-rotate(159deg)
            brightness(99%) contrast(103%);
        }

        @media screen and (max-width: 400px) {
          padding-top: 2rem;
        }
        @media screen and (max-width: 696px) {
          padding-top: 2rem;
        }
      }
    }
  }
}
