.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 8vh;
  background: transparent;
  transition: all 0.4s linear;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 900;
}

.scrolled {
  position: fixed;
  position: -webkit-fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(6px);
  width: 100%;
  -webkit-box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.3);
  box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.3);
}

.app__navbar-logo {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 2.5rem;
  z-index: 1;
  cursor: pointer;

  @media screen and (max-width: 900px) {
    display: none;
  }

  img {
    width: 200px;

    @media screen and (max-width: 900px) {
      width: 160px;
    }
  }
}

.app__mobile-logo {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 2.5rem;
  z-index: 1;

  @media screen and (min-width: 900px) {
    display: none;
  }

  img {
    width: 30px;
  }
}

.nav-links {
  display: flex;
  justify-content: space-around;
  width: 27%;
  list-style: none;
  margin: 0 0;

  li {
    flex-direction: column;
    position: relative;
    cursor: pointer;
    font-size: 0.8rem;
    font-family: var(--font-base);

    span:before,
    span:after {
      content: '';
      position: absolute;
      width: 0%;
      height: 2px;
      top: 40%;
      margin-top: -0.5px;
      background: var(--secondary-color);
    }

    span:before {
      left: -2.5px;
    }
    span:after {
      right: 2.5px;
      background: var(--secondary-color);
      transition: width 0.8s cubic-bezier(0.22, 0.61, 0.36, 1);
    }

    span:hover:before {
      background: var(--secondary-color);
      width: 104%;
      transition: width 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
    }

    span:hover:after {
      background: transparent;
      width: 100%;
      transition: 0s;
    }

    h3 {
      cursor: default;
      font-size: 0.9rem;
      text-transform: uppercase;
      font-weight: 600;
      letter-spacing: 0.1rem;
      font-family: var(--font-base);

      @media screen and (max-width: 400px) {
        display: none;
      }
      @media screen and (max-width: 696px) {
        display: none;
      }
    }
  }

  .linkItem {
    color: var(--white-color);
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.1rem;
    transition: all 0.3s ease-in-out;
    font-family: var(--font-base);
  }

  a {
    color: var(--white-color);
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.1rem;
    transition: all 0.3s ease-in-out;
    font-family: var(--font-base);
  }

  @media screen and (max-width: 900px) {
    display: none;
  }
}

.burger {
  display: none;
  margin: 0 1.5rem;
  cursor: pointer;
  z-index: 999;

  div {
    width: 25px;
    height: 3px;
    background-color: var(--white-color);
    margin: 5px;
    transition: all 0.3s ease;
  }
}

@media screen and (min-width: 3000px) {
  .nav-links {
    width: 18%;
  }
}

@media screen and (max-width: 2000px) {
  .nav-links {
    width: 32%;
  }
}

@media screen and (max-width: 1720px) {
  .nav-links {
    width: 33%;
  }
}

@media screen and (max-width: 1460px) {
  .nav-links {
    width: 40%;
  }
}

@media screen and (max-width: 1300px) {
  .nav-links {
    width: 45%;
  }
}

@media screen and (max-width: 1200px) {
  .nav-links {
    width: 45%;
  }
}

@media screen and (max-width: 1024px) {
  .nav-links {
    width: 60%;
  }
}

@media screen and (max-width: 900px) {
  .nav-links {
    position: absolute;
    right: 0px;
    height: 100vh;
    top: 0vh;
    padding-top: 170px;
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(6px);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0 0;
    transform: translatey(-100%);
    transition: transform 0.5s ease-in;

    li {
      opacity: 0;
      font-size: 20px;
    }
  }

  .burger {
    display: block;
  }
}

.nav-active {
  transform: translatey(0%);
}

@keyframes navLinkFade {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

// activate menu item animation
.list-about-active {
  @media screen and (max-width: 400px) {
    animation: navLinkFade 0.5s ease forwards 0.3s;
  }
  @media screen and (max-width: 696px) {
    animation: navLinkFade 0.5s ease forwards 0.3s;
  }
}

.list-tints-active {
  @media screen and (max-width: 400px) {
    animation: navLinkFade 0.1s ease forwards 0.35s;
  }
  @media screen and (max-width: 696px) {
    animation: navLinkFade 0.1s ease forwards 0.35s;
  }
}

.list-services-active {
  @media screen and (max-width: 400px) {
    animation: navLinkFade 0.1s ease forwards 0.4s;
  }
  @media screen and (max-width: 696px) {
    animation: navLinkFade 0.1s ease forwards 0.4s;
  }
}

.list-gallery-active {
  @media screen and (max-width: 400px) {
    animation: navLinkFade 0.15s ease forwards 0.45s;
  }
  @media screen and (max-width: 696px) {
    animation: navLinkFade 0.15s ease forwards 0.45s;
  }
}

.list-contact-active {
  @media screen and (max-width: 400px) {
    animation: navLinkFade 0.2s ease forwards 0.5s;
  }
  @media screen and (max-width: 696px) {
    animation: navLinkFade 0.2s ease forwards 0.5s;
  }
}

.list-phoneIcon {
  animation: navLinkFade 0.25s ease forwards 0.55s;
}

.toggle .line1 {
  transform: rotate(-45deg) translate(-5px, 6px);
}

.toggle .line2 {
  opacity: 0;
}

.toggle .line3 {
  transform: rotate(45deg) translate(-5px, -6px);
}

.nav-divider {
  color: var(--secondary-color);
  margin-top: -0.2rem;

  @media screen and (max-width: 400px) {
    display: none;
  }
  @media screen and (max-width: 696px) {
    display: none;
  }
  @media screen and (max-width: 1720px) {
    margin-top: 0;
  }
  @media screen and (max-width: 2000px) {
    margin-top: 0;
  }
  @media screen and (max-width: 4000px) {
    margin-top: 0;
  }
}

.phoneIcon {
  width: 13px;
  height: auto;
  margin-right: 8px;
  margin-top: 0;
}

.mobilePhoneIcon {
  opacity: 0;
  @media screen and (min-width: 900px) {
    display: none;
  }
}

.socialIcons__container__navBar {
  display: flex;
  flex-direction: row;
  @media screen and (min-width: 900px) {
    display: none;
  }
}

.socialIcons__icon__navBar {
  margin: 0 5px;
  text-align: center;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
  padding: 8px;

  span {
    position: relative;
    display: block;
    height: 50px;
    // margin-left: 70px;
    z-index: 2;

    svg {
      width: 20px;
      height: 20px;
      color: var(--white-color);
      transition: color 400ms cubic-bezier(0.4, 0, 0.2, 1);
    }

    &:hover {
      svg {
        color: var(--secondary-color);
      }
    }
  }
}
