.services__wrapper {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  margin-bottom: 5rem;

  @media screen and (max-width: 400px) {
    margin-bottom: 2rem;
  }
  @media screen and (max-width: 696px) {
    margin-bottom: 2rem;
  }

  .servicesHeader {
    height: 390px;
    width: 100%;
    display: flex;
    align-items: center;
    opacity: 0;

    @media screen and (max-width: 400px) {
      height: 290px;
    }
    @media screen and (max-width: 696px) {
      height: 290px;
    }

    h1 {
      text-transform: uppercase;
      font-weight: 600;
      letter-spacing: 6px;
      font-size: 70px;
      padding-left: 15rem;

      @media screen and (min-width: 270px) {
        text-align: center;
        font-size: 30px;
        padding-left: 0;
        width: 100%;
      }

      @media screen and (min-width: 375px) {
        text-align: center;
        font-size: 30px;
        padding-left: 0;
        width: 100%;
      }

      @media screen and (min-width: 696px) {
        text-align: center;
        font-size: 30px;
        padding-left: 0;
        width: 100%;
      }

      @media screen and (min-width: 1366px) {
        font-size: 55px;
        padding-left: 10rem;
        text-align: left;
        width: none;
      }

      @media screen and (min-width: 1440px) {
        font-size: 60px;
        padding-left: 10rem;
        text-align: left;
        width: none;
      }

      @media screen and (min-width: 1910px) {
        font-size: 70px;
        padding-left: 15rem;
        text-align: left;
        width: none;
      }
    }
  }
}

.servicesTintWrapper {
  width: 100%;
  height: 100%;
  //   background: darkblue;

  .firstRowWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 780px;
    grid-template-areas: 'picture description';
    // padding: 0 50px;

    @media screen and (max-width: 696px) {
      display: flex;
      flex-direction: column;
      margin-top: -10rem;
      padding-left: 0;
      padding-right: 0;
    }

    @media screen and (min-width: 3000px) {
      display: grid;
      flex-direction: none;
      margin-top: 0;
      padding-left: 50rem;
      padding-right: 50rem;
    }

    .leftCol1 {
      grid-area: picture;
      width: 100%;
      opacity: 0;

      img {
        object-fit: cover;
        width: 100%;
        height: 780px;

        @media screen and (min-width: 275px) {
          padding-left: 2rem;
          padding-right: 2rem;
          height: 380px;
          margin-top: 8rem;
        }
        @media screen and (min-width: 375px) {
          height: 480px;
          padding-left: 2rem;
          padding-right: 2rem;
          margin-top: 4rem;
        }
        @media screen and (min-width: 696px) {
          height: 480px;
          padding-left: 2rem;
          padding-right: 2rem;
          margin-top: 0;
        }
        @media screen and (min-width: 1024px) {
          height: 780px;
          padding-left: 0;
          padding-right: 0;
          margin-top: 0;
        }
      }
    }

    .rightCol1 {
      grid-area: description;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;

      @media screen and (min-width: 275px) {
        padding-left: 2rem;
        padding-right: 2rem;
      }
      @media screen and (min-width: 375px) {
        padding-left: 2rem;
        padding-right: 2rem;
      }
      @media screen and (min-width: 696px) {
        padding-left: 2rem;
        padding-right: 2rem;
      }

      @media screen and (min-width: 1366px) {
        padding-left: none;
        padding-right: none;
      }

      @media screen and (min-width: 1440px) {
        padding-left: none;
        padding-right: none;
      }

      @media screen and (min-width: 1910px) {
        padding-left: none;
        padding-right: none;
      }

      .rightCol1Container {
        width: 100%;
        height: 601px;
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (min-width: 275px) {
          margin-top: -2rem;
          width: 100%;
          margin-bottom: 8rem;
        }
        @media screen and (min-width: 375px) {
          margin-top: 0;
          width: 100%;
          margin-bottom: 5rem;
        }
        @media screen and (min-width: 696px) {
          margin-top: -5rem;
          width: 100%;
          margin-bottom: 0;
        }
        @media screen and (min-width: 1300px) {
          margin-top: -12rem;
          width: 603px;
          margin-bottom: 0;
        }
        @media screen and (min-width: 1420px) {
          margin-top: -5rem;
          width: 603px;
          margin-bottom: 0;
        }
        @media screen and (min-width: 1600px) {
          margin-top: -10rem;
          width: 100%;
          margin-bottom: 0;
        }
        @media screen and (min-width: 1900px) {
          margin-top: 0;
          width: 100%;
          margin-bottom: 0;
        }

        .rightCol1ContainerText {
          height: 361px;
          width: 703px;

          h1 {
            text-transform: uppercase;
            font-weight: 600;
            letter-spacing: 4px;
            font-size: 40px;
            // padding-left: 15rem;

            @media screen and (min-width: 280px) {
              font-size: 26px;
            }

            @media screen and (min-width: 375px) {
              font-size: 30px;
            }

            @media screen and (min-width: 696px) {
              font-size: 30px;
            }
            @media screen and (min-width: 1024px) {
              font-size: 40px;
            }
          }

          p {
            text-transform: uppercase;
            font-size: 16px;

            font-family: var(--font-base);
            padding-top: 1rem;
            color: var(--lightGray-color);
            padding-bottom: 2rem;
            letter-spacing: 1px;

            @media screen and (min-width: 280px) {
              font-size: 12px;
            }
            @media screen and (min-width: 375px) {
              font-size: 14px;
            }
            @media screen and (min-width: 696px) {
              font-size: 14px;
            }
            @media screen and (min-width: 1024px) {
              font-size: 16px;
            }
          }

          .rightCol1ContainerText__option {
            padding-top: 1rem;
            padding-right: 1rem;
            padding-bottom: 2rem;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            text-transform: uppercase;
            font-weight: 500;
            font-size: 13px;
            border-bottom: 2px solid var(--gray-color);
            letter-spacing: 1px;

            @media screen and (min-width: 275px) {
              font-size: 10px;
            }
            @media screen and (min-width: 375px) {
              font-size: 11px;
            }
            @media screen and (min-width: 696px) {
              font-size: 11px;
            }
            @media screen and (min-width: 1024px) {
              font-size: 13px;
            }

            .options__price {
              color: var(--secondary-color);
            }
          }

          .last__option {
            border-bottom: none;
          }

          .shade__options {
            text-align: center;
            color: var(--white-color);
            padding-top: 2rem;
          }
        }
      }
    }
  }

  .secondRowWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 780px;
    grid-template-areas: 'description picture';
    // padding: 0 50px;

    @media screen and (max-width: 696px) {
      display: flex;
      flex-direction: column;
      margin-top: -10rem;
      padding-left: 0;
      padding-right: 0;
    }

    @media screen and (min-width: 3000px) {
      display: grid;
      flex-direction: none;
      margin-top: 0;
      padding-left: 50rem;
      padding-right: 50rem;
    }

    .leftCol2 {
      grid-area: description;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;

      @media screen and (min-width: 275px) {
        padding-left: 2rem;
        padding-right: 2rem;
      }
      @media screen and (min-width: 375px) {
        padding-left: 2rem;
        padding-right: 2rem;
      }
      @media screen and (min-width: 696px) {
        padding-left: 2rem;
        padding-right: 2rem;
      }

      @media screen and (min-width: 1366px) {
        padding-left: none;
        padding-right: none;
      }

      @media screen and (min-width: 1440px) {
        padding-left: none;
        padding-right: none;
      }

      @media screen and (min-width: 1910px) {
        padding-left: none;
        padding-right: none;
      }

      .leftCol2Container {
        width: 100%;
        height: 601px;
        display: flex;
        justify-content: center;
        align-items: center;
        // margin-top: -5rem;

        @media screen and (min-width: 275px) {
          margin-top: 8rem;
          width: 100%;
        }
        @media screen and (min-width: 375px) {
          margin-top: 5rem;
          width: 100%;
        }
        @media screen and (min-width: 696px) {
          margin-top: -5rem;
          width: 100%;
        }
        @media screen and (min-width: 1300px) {
          margin-top: -12rem;
          width: 603px;
        }
        @media screen and (min-width: 1420px) {
          margin-top: -5rem;
          width: 603px;
        }
        @media screen and (min-width: 1600px) {
          margin-top: 0;
          width: 100%;
        }
        @media screen and (min-width: 1900px) {
          margin-top: 0;
          width: 100%;
        }

        .leftCol2ContainerText {
          height: 361px;
          width: 703px;

          h1 {
            text-transform: uppercase;
            font-weight: 600;
            letter-spacing: 4px;
            font-size: 40px;
            // padding-left: 15rem;

            @media screen and (min-width: 280px) {
              font-size: 26px;
            }

            @media screen and (min-width: 375px) {
              font-size: 30px;
            }

            @media screen and (min-width: 696px) {
              font-size: 30px;
            }
            @media screen and (min-width: 1024px) {
              font-size: 40px;
            }
          }

          p {
            text-transform: uppercase;
            font-size: 16px;

            font-family: var(--font-base);
            padding-top: 1rem;
            color: var(--lightGray-color);
            padding-bottom: 2rem;
            letter-spacing: 1px;

            @media screen and (min-width: 280px) {
              font-size: 12px;
            }
            @media screen and (min-width: 375px) {
              font-size: 14px;
            }
            @media screen and (min-width: 696px) {
              font-size: 14px;
            }
            @media screen and (min-width: 1024px) {
              font-size: 16px;
            }
          }

          .leftCol2ContainerText__option {
            padding-top: 2rem;
            padding-right: 1rem;
            padding-bottom: 2rem;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            text-transform: uppercase;
            font-weight: 500;
            font-size: 13px;
            border-bottom: 2px solid var(--gray-color);
            letter-spacing: 1px;

            @media screen and (min-width: 275px) {
              font-size: 10px;
            }
            @media screen and (min-width: 375px) {
              font-size: 11px;
            }
            @media screen and (min-width: 696px) {
              font-size: 11px;
            }
            @media screen and (min-width: 1024px) {
              font-size: 13px;
            }

            .options__price {
              color: var(--secondary-color);
            }
          }

          .last__option {
            border-bottom: none;
          }

          .shade__options {
            text-align: center;
            color: var(--white-color);
            padding-top: 2rem;
          }
        }
      }
    }

    .rightCol2 {
      grid-area: picture;
      width: 100%;
      opacity: 0;

      img {
        object-fit: cover;
        width: 100%;
        height: 780px;

        @media screen and (min-width: 275px) {
          padding-left: 2rem;
          padding-right: 2rem;
          height: 380px;
          margin-top: 8rem;
        }
        @media screen and (min-width: 375px) {
          height: 480px;
          padding-left: 2rem;
          padding-right: 2rem;
          margin-top: 4rem;
        }
        @media screen and (min-width: 696px) {
          height: 480px;
          padding-left: 2rem;
          padding-right: 2rem;
          margin-top: 0;
        }
        @media screen and (min-width: 1024px) {
          height: 780px;
          padding-left: 0;
          padding-right: 0;
          margin-top: 0;
        }
      }
    }
  }

  .thirdRowWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 780px;
    grid-template-areas: 'picture description';
    // padding: 0 50px;

    @media screen and (max-width: 696px) {
      display: flex;
      flex-direction: column;
      margin-top: 0;
      padding-left: 0;
      padding-right: 0;
    }

    @media screen and (min-width: 3000px) {
      display: grid;
      flex-direction: none;
      margin-top: 0;
      padding-left: 50rem;
      padding-right: 50rem;
    }

    .leftCol3 {
      grid-area: picture;
      opacity: 0;

      img {
        object-fit: cover;
        width: 100%;
        height: 780px;

        @media screen and (min-width: 275px) {
          padding-left: 2rem;
          padding-right: 2rem;
          height: 380px;
          margin-top: 2rem;
        }
        @media screen and (min-width: 375px) {
          height: 480px;
          padding-left: 2rem;
          padding-right: 2rem;
          margin-top: 2rem;
        }
        @media screen and (min-width: 696px) {
          height: 480px;
          padding-left: 2rem;
          padding-right: 2rem;
          margin-top: 0;
        }
        @media screen and (min-width: 1024px) {
          height: 780px;
          padding-left: 0;
          padding-right: 0;
          margin-top: 0;
        }
      }
    }

    .rightCol3 {
      grid-area: description;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: -8rem;
      opacity: 0;

      @media screen and (min-width: 275px) {
        padding-left: 2rem;
        padding-right: 2rem;
      }
      @media screen and (min-width: 375px) {
        padding-left: 2rem;
        padding-right: 2rem;
      }
      @media screen and (min-width: 696px) {
        padding-left: 2rem;
        padding-right: 2rem;
      }

      @media screen and (min-width: 1366px) {
        padding-left: none;
        padding-right: none;
      }

      @media screen and (min-width: 1420px) {
        padding-left: none;
        padding-right: none;
      }

      @media screen and (min-width: 1910px) {
        padding-left: none;
        padding-right: none;
      }

      .rightCol3Container {
        width: 100%;
        height: 601px;
        display: flex;
        justify-content: center;
        align-items: center;
        // margin-top: -2rem;

        @media screen and (min-width: 275px) {
          margin-top: 3rem;
          width: 100%;
        }
        @media screen and (min-width: 375px) {
          margin-top: 5rem;
          width: 100%;
        }
        @media screen and (min-width: 696px) {
          margin-top: -5rem;
          width: 100%;
        }
        @media screen and (min-width: 1300px) {
          margin-top: -12rem;
          width: 603px;
        }
        @media screen and (min-width: 1420px) {
          margin-top: -4rem;
          width: 603px;
        }
        @media screen and (min-width: 1600px) {
          margin-top: 0;
          width: 100%;
        }
        @media screen and (min-width: 1900px) {
          margin-top: 0;
          width: 100%;
        }

        .rightCol3ContainerText {
          height: 361px;
          width: 703px;

          h1 {
            text-transform: uppercase;
            font-weight: 600;
            letter-spacing: 4px;
            font-size: 40px;
            // padding-left: 15rem;

            @media screen and (min-width: 280px) {
              font-size: 26px;
            }

            @media screen and (min-width: 375px) {
              font-size: 30px;
            }

            @media screen and (min-width: 696px) {
              font-size: 30px;
            }
            @media screen and (min-width: 1024px) {
              font-size: 40px;
            }
          }

          p {
            text-transform: uppercase;
            font-size: 16px;

            font-family: var(--font-base);
            padding-top: 1rem;
            color: var(--lightGray-color);
            padding-bottom: 2rem;
            letter-spacing: 1px;

            @media screen and (min-width: 275px) {
              font-size: 12px;
            }
            @media screen and (min-width: 375px) {
              font-size: 14px;
            }
            @media screen and (min-width: 696px) {
              font-size: 14px;
            }
            @media screen and (min-width: 1024px) {
              font-size: 16px;
            }
          }

          .rightCol3ContainerText__option {
            padding-top: 2rem;
            padding-right: 1rem;
            padding-bottom: 2rem;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            text-transform: uppercase;
            font-weight: 500;
            font-size: 13px;
            border-bottom: 2px solid var(--gray-color);
            letter-spacing: 1px;

            @media screen and (min-width: 275px) {
              font-size: 10px;
            }
            @media screen and (min-width: 375px) {
              font-size: 11px;
            }
            @media screen and (min-width: 696px) {
              font-size: 11px;
            }
            @media screen and (min-width: 1024px) {
              font-size: 13px;
            }

            .options__price {
              color: var(--secondary-color);
            }
          }

          .last__option {
            border-bottom: none;
          }

          .shade__options {
            text-align: center;
            color: var(--white-color);
            padding-top: 2rem;
          }
        }
      }
    }
  }

  .fourthRowWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 780px;
    grid-template-areas: 'description picture';
    // padding: 0 50px;

    @media screen and (min-width: 275px) {
      display: flex;
      flex-direction: column;
      margin-top: 8rem;
    }
    @media screen and (min-width: 375px) {
      display: flex;
      flex-direction: column;
      margin-top: 4rem;
    }

    @media screen and (min-width: 390px) {
      display: flex;
      flex-direction: column;
      margin-top: 2rem;
    }

    @media screen and (min-width: 696px) {
      display: flex;
      flex-direction: column;
      margin-top: 0;
    }
    @media screen and (min-width: 1024px) {
      display: grid;
      flex-direction: none;
      margin-top: 0;
    }

    @media screen and (min-width: 3000px) {
      display: grid;
      flex-direction: none;
      margin-top: 0;
      padding-left: 50rem;
      padding-right: 50rem;
    }

    .leftCol4 {
      grid-area: description;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: -3rem;
      opacity: 0;

      @media screen and (min-width: 275px) {
        padding-left: 2rem;
        padding-right: 2rem;
      }
      @media screen and (min-width: 375px) {
        padding-left: 2rem;
        padding-right: 2rem;
      }
      @media screen and (min-width: 696px) {
        padding-left: 2rem;
        padding-right: 2rem;
      }

      @media screen and (min-width: 1366px) {
        padding-left: none;
        padding-right: none;
      }

      @media screen and (min-width: 1420px) {
        padding-left: none;
        padding-right: none;
      }

      @media screen and (min-width: 1910px) {
        padding-left: none;
        padding-right: none;
      }

      .leftCol4Container {
        width: 100%;
        height: 601px;
        display: flex;
        justify-content: center;
        align-items: center;
        // margin-top: -5rem;

        @media screen and (min-width: 275px) {
          margin-top: 10rem;
          width: 100%;
        }
        @media screen and (min-width: 375px) {
          margin-top: 8rem;
          width: 100%;
        }
        @media screen and (min-width: 696px) {
          margin-top: -5rem;
          width: 100%;
        }
        @media screen and (min-width: 1300px) {
          margin-top: -12rem;
          width: 603px;
        }
        @media screen and (min-width: 1420px) {
          margin-top: -5rem;
          width: 603px;
        }
        @media screen and (min-width: 1600px) {
          margin-top: 0;
          width: 100%;
        }
        @media screen and (min-width: 1900px) {
          margin-top: 0;
          width: 100%;
        }

        .leftCol4ContainerText {
          height: 361px;
          width: 703px;

          h1 {
            text-transform: uppercase;
            font-weight: 600;
            letter-spacing: 4px;
            font-size: 40px;
            // padding-left: 15rem;

            @media screen and (min-width: 280px) {
              font-size: 26px;
            }

            @media screen and (min-width: 375px) {
              font-size: 30px;
            }

            @media screen and (min-width: 696px) {
              font-size: 30px;
            }
            @media screen and (min-width: 1024px) {
              font-size: 40px;
            }
          }

          p {
            text-transform: uppercase;
            font-size: 16px;

            font-family: var(--font-base);
            padding-top: 1rem;
            color: var(--lightGray-color);
            padding-bottom: 2rem;
            letter-spacing: 1px;

            @media screen and (min-width: 280px) {
              font-size: 12px;
            }
            @media screen and (min-width: 375px) {
              font-size: 14px;
            }
            @media screen and (min-width: 696px) {
              font-size: 14px;
            }
            @media screen and (min-width: 1024px) {
              font-size: 16px;
            }
          }

          .leftCol4ContainerText__option {
            padding-top: 2rem;
            padding-right: 1rem;
            padding-bottom: 2rem;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            text-transform: uppercase;
            font-weight: 500;
            font-size: 13px;
            border-bottom: 2px solid var(--gray-color);
            letter-spacing: 1px;

            @media screen and (min-width: 280px) {
              font-size: 10px;
            }
            @media screen and (min-width: 375px) {
              font-size: 11px;
            }
            @media screen and (min-width: 696px) {
              font-size: 11px;
            }
            @media screen and (min-width: 1024px) {
              font-size: 13px;
            }

            .options__price {
              color: var(--secondary-color);
            }
          }

          .last__option {
            border-bottom: none;
          }

          .shade__options {
            text-align: center;
            color: var(--white-color);
            padding-top: 2rem;
          }
        }
      }
    }

    .rightCol4 {
      grid-area: picture;
      width: 100%;
      opacity: 0;

      .rightCol4__img {
        object-fit: cover;
        width: 100%;
        height: 780px;

        @media screen and (min-width: 280px) {
          padding-left: 2rem;
          padding-right: 2rem;
          height: 380px;
          margin-top: 15rem;
        }
        @media screen and (min-width: 375px) {
          height: 480px;
          padding-left: 2rem;
          padding-right: 2rem;
          margin-top: 10rem;
        }
        @media screen and (min-width: 696px) {
          height: 480px;
          padding-left: 2rem;
          padding-right: 2rem;
          margin-top: 0;
        }
        @media screen and (min-width: 1024px) {
          height: 780px;
          padding-left: 0;
          padding-right: 0;
          margin-top: 0;
        }
      }
    }
  }

  .fifthRowWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 780px;
    grid-template-areas: 'picture description';
    // padding: 0 50px;

    @media screen and (max-width: 400px) {
      display: flex;
      flex-direction: column;
    }
    @media screen and (max-width: 696px) {
      display: flex;
      flex-direction: column;
    }
    @media screen and (min-width: 3000px) {
      display: grid;
      flex-direction: none;
      margin-top: 0;
      padding-left: 50rem;
      padding-right: 50rem;
    }

    .leftCol5 {
      grid-area: picture;
      opacity: 0;

      img {
        object-fit: cover;
        width: 100%;
        height: 780px;

        @media screen and (min-width: 280px) {
          padding-left: 2rem;
          padding-right: 2rem;
          height: 380px;
          margin-top: 2rem;
        }
        @media screen and (min-width: 375px) {
          height: 480px;
          padding-left: 2rem;
          padding-right: 2rem;
          margin-top: 2rem;
        }
        @media screen and (min-width: 696px) {
          height: 480px;
          padding-left: 2rem;
          padding-right: 2rem;
          margin-top: 0;
        }
        @media screen and (min-width: 1024px) {
          height: 780px;
          padding-left: 0;
          padding-right: 0;
          margin-top: 0;
        }
      }
    }

    .rightCol5 {
      grid-area: description;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: -8rem;
      opacity: 0;

      @media screen and (min-width: 275px) {
        padding-left: 2rem;
        padding-right: 2rem;
      }
      @media screen and (min-width: 375px) {
        padding-left: 2rem;
        padding-right: 2rem;
      }
      @media screen and (min-width: 696px) {
        padding-left: 2rem;
        padding-right: 2rem;
      }

      @media screen and (min-width: 1366px) {
        padding-left: none;
        padding-right: none;
      }

      @media screen and (min-width: 1420px) {
        padding-left: none;
        padding-right: none;
      }

      @media screen and (min-width: 1910px) {
        padding-left: none;
        padding-right: none;
      }

      .rightCol5Container {
        width: 100%;
        height: 601px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: -6rem;

        @media screen and (min-width: 275px) {
          margin-top: 3rem;
          width: 100%;
        }
        @media screen and (min-width: 375px) {
          margin-top: 5rem;
          width: 100%;
        }
        @media screen and (min-width: 696px) {
          margin-top: -5rem;
          width: 100%;
        }
        @media screen and (min-width: 1300px) {
          margin-top: -12rem;
          width: 603px;
        }
        @media screen and (min-width: 1420px) {
          margin-top: -8rem;
          width: 603px;
        }
        @media screen and (min-width: 1600px) {
          margin-top: -5rem;
          width: 100%;
        }
        @media screen and (min-width: 1900px) {
          margin-top: -5rem;
          width: 100%;
        }

        .rightCol5ContainerText {
          height: 361px;
          width: 703px;

          h1 {
            text-transform: uppercase;
            font-weight: 600;
            letter-spacing: 4px;
            font-size: 40px;
            // padding-left: 15rem;

            @media screen and (min-width: 280px) {
              font-size: 26px;
            }

            @media screen and (min-width: 375px) {
              font-size: 30px;
            }

            @media screen and (min-width: 696px) {
              font-size: 30px;
            }
            @media screen and (min-width: 1024px) {
              font-size: 40px;
            }
          }

          p {
            text-transform: uppercase;
            font-size: 16px;

            font-family: var(--font-base);
            padding-top: 1rem;
            color: var(--lightGray-color);
            padding-bottom: 2rem;
            letter-spacing: 1px;

            @media screen and (min-width: 280px) {
              font-size: 12px;
            }
            @media screen and (min-width: 375px) {
              font-size: 14px;
            }
            @media screen and (min-width: 696px) {
              font-size: 14px;
            }
            @media screen and (min-width: 1024px) {
              font-size: 16px;
            }
          }

          .rightCol5ContainerText__option {
            padding-top: 2rem;
            padding-right: 1rem;
            padding-bottom: 2rem;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            text-transform: uppercase;
            font-weight: 500;
            font-size: 13px;
            border-bottom: 2px solid var(--gray-color);
            letter-spacing: 1px;

            @media screen and (min-width: 280px) {
              font-size: 10px;
            }
            @media screen and (min-width: 375px) {
              font-size: 11px;
            }
            @media screen and (min-width: 696px) {
              font-size: 11px;
            }
            @media screen and (min-width: 1024px) {
              font-size: 13px;
            }

            .options__price {
              color: var(--secondary-color);
            }
          }

          .last__option {
            border-bottom: none;
          }

          .shade__options {
            text-align: center;
            color: var(--white-color);
            padding-top: 2rem;
          }
        }
      }
    }
  }
}

.services__disclaimer {
  text-transform: uppercase;
  font-size: 16px;
  text-align: center;
  font-family: var(--font-base);
  padding-top: 1rem;
  color: var(--lightGray-color);
  padding-top: 5rem;
  letter-spacing: 1px;
  width: 100%;

  @media screen and (min-width: 280px) {
    font-size: 12px;
    margin-top: 20rem;
  }

  @media screen and (min-width: 375px) {
    font-size: 12px;
    margin-top: 15rem;
  }

  @media screen and (min-width: 696px) {
    font-size: 12px;
    margin-top: 15rem;
  }
  @media screen and (min-width: 1024px) {
    font-size: 16px;
    margin-top: 0;
  }
}

.bottomAnimation {
  opacity: 0;
}

.glitchText__services {
  position: relative;
  max-width: 1200px;

  @media screen and (max-width: 400px) {
    max-width: 385px;
  }
  @media screen and (max-width: 696px) {
    max-width: 385px;
  }
}
.glitchText__services::before,
.glitchText__services::after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.glitchText__services::before {
  left: 2px;
  clip: rect(79px, 1200px, 86px, 0);
  text-shadow: -1px 0 red;
  background: #000;
  animation: brasil-anim-2 1s infinite linear alternate-reverse;
}

.glitchText__services::after {
  /* variation */
  left: -2px;
  clip: rect(79px, 1200px, 86px, 0);
  text-shadow: -1px 0 blue;
  background: #000;
  animation: brasil-anim-1 1s infinite linear alternate-reverse;
  animation-delay: -1s;
}

@keyframes brasil-anim-1 {
  0% {
    clip: rect(20px, 1200px, 76px, 0);
  }
  20% {
    clip: rect(19px, 1200px, 16px, 0);
  }
  40% {
    clip: rect(16px, 1200px, 3px, 0);
  }
  60% {
    clip: rect(62px, 1200px, 78px, 0);
  }
  80% {
    clip: rect(25px, 1200px, 13px, 0);
  }
  100% {
    clip: rect(53px, 1200px, 86px, 0);
  }
}

@keyframes brasil-anim-2 {
  0% {
    clip: rect(79px, 1200px, 86px, 0);
  }

  20% {
    clip: rect(20px, 1200px, 30px, 0);
  }

  40% {
    clip: rect(25px, 1200px, 5px, 0);
  }

  60% {
    clip: rect(65px, 1200px, 85px, 0);
  }

  80% {
    clip: rect(120px, 1200px, 145px, 0);
  }

  100% {
    clip: rect(95px, 1200px, 75px, 0);
  }
}
