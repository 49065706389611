.gallery__wrapper {
  height: 100%;
  width: 100%;
  margin-bottom: 10rem;

  background: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.7));
  // url('../../assets/droplet3.png')
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;

  .galleryHeader {
    height: 390px;
    width: 100%;
    display: flex;
    align-items: center;
    opacity: 0;

    @media screen and (max-width: 400px) {
      height: 290px;
    }
    @media screen and (max-width: 696px) {
      height: 290px;
    }

    h1 {
      text-transform: uppercase;
      font-weight: 600;
      letter-spacing: 6px;
      font-size: 70px;
      padding-left: 15rem;

      @media screen and (min-width: 270px) {
        text-align: center;
        font-size: 30px;
        padding-left: 0;
        width: 100%;
      }

      @media screen and (min-width: 375px) {
        text-align: center;
        font-size: 30px;
        padding-left: 0;
        width: 100%;
      }

      @media screen and (min-width: 696px) {
        text-align: center;
        font-size: 30px;
        padding-left: 0;
        width: 100%;
      }

      @media screen and (min-width: 1366px) {
        font-size: 55px;
        padding-left: 10rem;
        text-align: left;
        width: none;
      }

      @media screen and (min-width: 1440px) {
        font-size: 60px;
        padding-left: 10rem;
        text-align: left;
        width: none;
      }

      @media screen and (min-width: 1910px) {
        font-size: 70px;
        padding-left: 15rem;
        text-align: left;
        width: none;
      }
    }
  }

  .image__wrapper {
    max-width: 70rem;
    margin: 0 auto;
    display: grid;
    grid-gap: 1em;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-auto-rows: 205px;

    @media screen and (max-width: 400px) {
      grid-auto-rows: 300px;
    }
    @media screen and (max-width: 696px) {
      grid-auto-rows: 300px;
    }

    .gallery__pics {
      opacity: 0;
      -webkit-transition: all 350ms ease;
      transition: all 350ms ease;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .model {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.9);
    backdrop-filter: blur(6px);
    transition: opacity 0.4s ease, visibility 0.4s ease,
      transform 0.5s ease-in-out;
    visibility: hidden;
    opacity: 0;
    transform: scale(0);
    z-index: 999;
  }

  .model.open {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
  }

  .model img {
    width: auto;
    max-width: 100%;
    max-height: 100%;
    height: auto;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 20px 0 20px;
    margin: 0 auto;
  }

  .model.open svg {
    position: fixed;
    top: 10px;
    right: 10px;
    width: 2.5rem;
    height: 2.5rem;
    padding: 5px;
    // background-color: rgba(0, 0, 0, 0.4);
    filter: invert(100%) sepia(76%) saturate(0%) hue-rotate(81deg)
      brightness(105%) contrast(101%);
    cursor: pointer;
  }
}

.glitchText__gallery {
  position: relative;
  max-width: 1200px;

  @media screen and (max-width: 400px) {
    max-width: 385px;
  }
  @media screen and (max-width: 696px) {
    max-width: 385px;
  }
}
.glitchText__gallery::before,
.glitchText__gallery::after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.glitchText__gallery::before {
  left: 2px;
  clip: rect(79px, 1200px, 86px, 0);
  text-shadow: -1px 0 red;
  background: #000;
  animation: brasil-anim-2 1s infinite linear alternate-reverse;
}

.glitchText__gallery::after {
  /* variation */
  left: -2px;
  clip: rect(79px, 1200px, 86px, 0);
  text-shadow: -1px 0 blue;
  background: #000;
  animation: brasil-anim-1 1s infinite linear alternate-reverse;
  animation-delay: -1s;
}

@keyframes brasil-anim-1 {
  0% {
    clip: rect(20px, 1200px, 76px, 0);
  }
  20% {
    clip: rect(19px, 1200px, 16px, 0);
  }
  40% {
    clip: rect(16px, 1200px, 3px, 0);
  }
  60% {
    clip: rect(62px, 1200px, 78px, 0);
  }
  80% {
    clip: rect(25px, 1200px, 13px, 0);
  }
  100% {
    clip: rect(53px, 1200px, 86px, 0);
  }
}

@keyframes brasil-anim-2 {
  0% {
    clip: rect(79px, 1200px, 86px, 0);
  }

  20% {
    clip: rect(20px, 1200px, 30px, 0);
  }

  40% {
    clip: rect(25px, 1200px, 5px, 0);
  }

  60% {
    clip: rect(65px, 1200px, 85px, 0);
  }

  80% {
    clip: rect(120px, 1200px, 145px, 0);
  }

  100% {
    clip: rect(95px, 1200px, 75px, 0);
  }
}

.gallery__imageSlider__container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  opacity: 0;

  @media screen and (max-width: 400px) {
    display: none;
  }
  @media screen and (max-width: 696px) {
    display: none;
  }

  .gallery__imageSlider {
    width: 100%;
    height: 350px;
    display: flex;
    flex-direction: row;
    margin-bottom: 12rem;
    margin-top: -8rem;
    padding-left: 24.5rem;
    padding-right: 24rem;

    @media screen and (min-width: 1326px) {
      padding-left: 8rem;
      padding-right: 7rem;
    }

    @media screen and (min-width: 1420px) {
      padding-left: 9.5rem;
      padding-right: 9rem;
    }

    @media screen and (min-width: 1660px) {
      padding-left: 17rem;
      padding-right: 17rem;
    }

    @media screen and (min-width: 1700px) {
      padding-left: 19rem;
      padding-right: 18rem;
    }

    @media screen and (min-width: 1900px) {
      padding-left: 24.5rem;
      padding-right: 24rem;
    }
    @media screen and (min-width: 3000px) {
      padding-left: 72.5rem;
      padding-right: 72rem;
    }

    img {
      object-fit: cover;
      width: 550px;
      height: 350px;
      // padding-left: 1rem;

      @media screen and (min-width: 1366px) {
        width: 550px;
      }

      @media screen and (min-width: 1420px) {
        width: 550px;
      }

      @media screen and (min-width: 1660px) {
        width: 550px;
      }

      @media screen and (min-width: 1700px) {
        width: 540px;
      }

      @media screen and (min-width: 1900px) {
        width: 550px;
      }

      @media screen and (min-width: 3000px) {
        width: 550px;
      }
    }
  }
}
