.home__container {
  background: var(--background-color);
  height: 100%;
  // margin-top: -60px;

  .home__popPackContainer {
    // height: 100%;

    // background: linear-gradient(-90deg, #000000 5%, transparent 40%);
    h1 {
      text-transform: uppercase;
      letter-spacing: 2px;

      font-size: 60px;
    }

    // background: linear-gradient(-90deg, #da0000 5%, transparent 40%);
  }
}
