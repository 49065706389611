.app__testimonial {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 5%;
  margin-top: 5%;
  padding-top: 20px;
  min-height: 500px;
  opacity: 0;

  @media screen and (max-width: 400px) {
    margin-top: 0;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  @media screen and (max-width: 696px) {
    margin-top: 0;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .app__testimonial-btns {
    flex-direction: row;
    margin-top: 1rem;

    div {
      width: 50px;
      height: 50px;
      margin: 1rem;
      transition: all 0.3s ease-in-out;
      background-color: var(--lightBlack-color);

      svg {
        width: 30px;
        height: 30px;
        cursor: pointer;
        color: var(--white-color);
        transition: color 400ms cubic-bezier(0.4, 0, 0.2, 1);

        &:hover {
          color: var(--secondary-color);
        }
      }
    }
  }
}

.app__testimonial-item {
  width: 60%;
  min-height: 320px;
  background-color: var(--lightBlack-color);
  display: flex;
  padding: 2rem;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(6px);

  transition: all 2s ease-in-out;

  @media screen and (min-width: 2000px) {
    min-height: 450px;
    width: 50%;
  }

  @media screen and (max-width: 850px) {
    width: 100%;
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }

  .app__testimonial-item__img {
    display: flex;
    // flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-bottom: 2rem;

    img {
      width: 40px;
    }
  }
}

.app__testimonial-content {
  flex: 1;
  height: 100%;
  padding: 0 2rem;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;

  p {
    font-size: 1.25rem;
    line-height: 2rem;
    color: var(--white-color);
    font-family: var(--font-base);

    @media screen and (max-width: 400px) {
      font-size: 0.9rem;
    }
  }

  h4 {
    font-size: 1rem;
    color: var(--lightGray-color);
    font-family: var(--font-base);
    padding-top: 30%;
    font-weight: 600;

    @media screen and (max-width: 400px) {
      font-size: 0.8rem;
    }

    @media screen and (max-width: 696px) {
      font-size: 0.8rem;
    }
  }

  h5 {
    font-size: 0.9rem;
    font-weight: 500;
    color: var(--gray-color);
    margin-top: 5px;

    @media screen and (max-width: 400px) {
      font-size: 0.7rem;
    }
    @media screen and (max-width: 696px) {
      font-size: 0.7rem;
    }
  }
}

.popup {
  visibility: hidden;
  opacity: 0;
  transition: opacity 250ms ease-in, visibility 0ms ease-in 250ms;
}

.popup.show {
  visibility: visible;
  opacity: 1;
  transition: opacity 250ms ease-in, visibility 0ms ease-in 0ms;
}
