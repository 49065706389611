.homeServicesCards__section {
  width: 100%;
  height: 100%;
  margin-top: -18.7rem;
  z-index: 2;

  .homeServicesCards__cards {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    @media screen and (max-width: 400px) {
      flex-direction: column;
      align-items: center;
    }
    @media screen and (max-width: 696px) {
      flex-direction: column;
      align-items: center;
    }

    @media screen and (min-width: 2540px) {
      padding-left: 20rem;
      padding-right: 20rem;
    }
    @media screen and (min-width: 3000px) {
      padding-left: 45rem;
      padding-right: 45rem;
    }

    .homeServicesCards__card {
      height: 606px;
      width: 433px;

      @media screen and (max-width: 400px) {
        height: 522px;
        width: 330px;
      }
      @media screen and (max-width: 696px) {
        height: 522px;
        width: 330px;
      }

      position: relative;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }

      .homeServicesCards__card-description1 {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        width: 353px;
        height: 306px;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 10;
        padding: 40px;
        background: var(--secondary-color);
        margin-left: 80px;

        @media screen and (max-width: 400px) {
          width: 250px;
          height: 322px;
          padding: 35px;
        }
        @media screen and (max-width: 696px) {
          width: 250px;
          height: 322px;
          padding: 35px;
        }

        .homeServicesCards__topDescription {
          display: flex;
          justify-content: flex-start;
          flex-direction: row;

          h4 {
            font-size: 30px;
            font-family: var(--font-base);
            font-weight: 600;
            color: var(--background-color);
            padding-top: 5px;
            padding-right: 10px;

            @media screen and (max-width: 400px) {
              font-size: 20px;
            }
            @media screen and (max-width: 696px) {
              font-size: 20px;
            }
          }

          h1 {
            color: var(--background-color);
            font-weight: 600;
            font-size: 60px;
            font-family: var(--font-base);

            @media screen and (max-width: 400px) {
              font-size: 50px;
            }
            @media screen and (max-width: 696px) {
              font-size: 50px;
            }
          }
        }

        h2 {
          color: var(--background-color);
          text-transform: uppercase;
          letter-spacing: 2px;
          font-size: 20px;
          font-weight: 600;
          font-family: var(--font-base);

          @media screen and (max-width: 400px) {
            font-size: 18px;
          }
          @media screen and (max-width: 696px) {
            font-size: 18px;
          }
        }

        p {
          text-transform: uppercase;
          font-size: 14px;
          color: var(--lightBlack-color);
          font-family: var(--font-base);
          padding-top: 1rem;

          @media screen and (max-width: 400px) {
            font-size: 13px;
            line-height: 20px;
          }
          @media screen and (max-width: 696px) {
            font-size: 13px;
            line-height: 20px;
          }
        }
      }

      .homeServicesCards__card-description2 {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        width: 353px;
        height: 306px;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 10;
        padding: 40px;
        background: var(--background-color);
        margin-left: 80px;

        @media screen and (max-width: 400px) {
          width: 250px;
          height: 322px;
          padding: 35px;
        }
        @media screen and (max-width: 696px) {
          width: 250px;
          height: 322px;
          padding: 35px;
        }

        .homeServicesCards__topDescription {
          display: flex;
          justify-content: flex-start;
          flex-direction: row;

          h4 {
            font-size: 30px;
            font-family: var(--font-base);
            font-weight: 600;
            color: var(--white-color);
            padding-top: 5px;
            padding-right: 10px;

            @media screen and (max-width: 400px) {
              font-size: 20px;
            }
            @media screen and (max-width: 696px) {
              font-size: 20px;
            }
          }

          h1 {
            color: var(--white-color);
            font-weight: 600;
            font-size: 60px;
            font-family: var(--font-base);

            @media screen and (max-width: 400px) {
              font-size: 50px;
            }
            @media screen and (max-width: 696px) {
              font-size: 50px;
            }
          }
        }

        h2 {
          color: var(--white-color);
          text-transform: uppercase;
          letter-spacing: 2px;
          font-size: 20px;
          font-weight: 600;
          font-family: var(--font-base);

          @media screen and (max-width: 400px) {
            font-size: 18px;
          }
          @media screen and (max-width: 696px) {
            font-size: 18px;
          }
        }

        p {
          text-transform: uppercase;
          font-size: 14px;
          color: var(--lightGray-color);
          font-family: var(--font-base);
          padding-top: 1rem;

          @media screen and (max-width: 400px) {
            font-size: 13px;
            line-height: 20px;
          }
          @media screen and (max-width: 696px) {
            font-size: 13px;
            line-height: 20px;
          }
        }
      }

      .homeServicesCards__card-description3 {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        width: 353px;
        height: 306px;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 10;
        padding: 40px;
        background: var(--secondary-color);
        margin-left: 80px;

        @media screen and (max-width: 400px) {
          width: 250px;
          height: 322px;
          padding: 35px;
        }
        @media screen and (max-width: 696px) {
          width: 250px;
          height: 322px;
          padding: 35px;
        }

        .homeServicesCards__topDescription {
          display: flex;
          justify-content: flex-start;
          flex-direction: row;

          h4 {
            font-size: 30px;
            font-family: var(--font-base);
            font-weight: 600;
            color: var(--background-color);
            padding-top: 5px;
            padding-right: 10px;

            @media screen and (max-width: 400px) {
              font-size: 20px;
            }
            @media screen and (max-width: 696px) {
              font-size: 20px;
            }
          }

          h1 {
            color: var(--background-color);
            font-weight: 600;
            font-size: 60px;
            font-family: var(--font-base);

            @media screen and (max-width: 400px) {
              font-size: 50px;
            }
            @media screen and (max-width: 696px) {
              font-size: 50px;
            }
          }
        }

        h2 {
          color: var(--background-color);
          text-transform: uppercase;
          letter-spacing: 2px;
          font-size: 20px;
          font-weight: 600;
          font-family: var(--font-base);

          @media screen and (max-width: 400px) {
            font-size: 18px;
          }
          @media screen and (max-width: 696px) {
            font-size: 18px;
          }
        }

        p {
          text-transform: uppercase;
          font-size: 14px;
          color: var(--lightBlack-color);
          font-family: var(--font-base);
          padding-top: 1rem;

          @media screen and (max-width: 400px) {
            font-size: 13px;
            line-height: 20px;
          }
          @media screen and (max-width: 696px) {
            font-size: 13px;
            line-height: 20px;
          }
        }
      }
    }
  }
}

.arrow-button span {
  font-size: 14px;
  line-height: 1.9;
  text-decoration: none;
  color: var(--background-color);
  font-family: var(--font-base);
  transition: all 0.2s linear;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: flex;
  justify-content: flex-start;
  padding-top: 1rem;
  -moz-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  cursor: pointer;

  &:hover {
    color: var(--gray-color);
  }
  &::after {
    padding-left: 6px;
    content: url(https://addsomebrown.com/assets/img/arrow_right_black.svg);
    display: inline-block;
    transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -webkit-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
  }
  &:hover::after {
    padding-left: 6px;
    content: url(https://addsomebrown.com/assets/img/arrow_right_black.svg);
    display: inline-block;
    padding-left: 12px;
    filter: invert(26%) sepia(5%) saturate(24%) hue-rotate(317deg)
      brightness(94%) contrast(92%);
  }

  @media screen and (max-width: 400px) {
    padding-top: 2rem;
  }
  @media screen and (max-width: 696px) {
    padding-top: 2rem;
  }
}

.arrow-button2 span {
  font-size: 14px;
  line-height: 1.9;
  text-decoration: none;
  color: var(--white-color);
  font-family: var(--font-base);
  transition: all 0.2s linear;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: flex;
  justify-content: flex-start;
  padding-top: 1rem;
  -moz-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  cursor: pointer;

  &:hover {
    color: var(--lightGray-color);
  }
  &::after {
    padding-left: 6px;
    content: url(https://addsomebrown.com/assets/img/arrow_right_black.svg);
    display: inline-block;
    transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -webkit-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    filter: invert(100%) sepia(88%) saturate(49%) hue-rotate(148deg)
      brightness(112%) contrast(113%);
  }
  &:hover::after {
    padding-left: 6px;
    content: url(https://addsomebrown.com/assets/img/arrow_right_black.svg);
    display: inline-block;
    padding-left: 12px;
    filter: invert(58%) sepia(3%) saturate(16%) hue-rotate(328deg)
      brightness(87%) contrast(85%);
  }

  @media screen and (max-width: 400px) {
    padding-top: 2rem;
  }
  @media screen and (max-width: 696px) {
    padding-top: 2rem;
  }
}

.firstCard,
.secondCard {
  margin-bottom: 2rem;
}
