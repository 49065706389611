.tintsMaterials__wrapper {
  width: 100%;
  height: 100%;
  margin-bottom: 10rem;
  padding-left: 200px;
  padding-right: 200px;

  @media screen and (min-width: 275px) {
    padding-left: 2rem;
    padding-right: 2rem;
    margin-bottom: 5rem;
  }
  @media screen and (min-width: 375px) {
    padding-left: 2rem;
    padding-right: 2rem;
    margin-bottom: 5rem;
  }
  @media screen and (min-width: 696px) {
    padding-left: 2rem;
    padding-right: 2rem;
    margin-bottom: 5rem;
  }
  @media screen and (min-width: 1300px) {
    padding-left: 120px;
    padding-right: 200px;
    margin-bottom: 10rem;
  }
  @media screen and (min-width: 1420px) {
    padding-left: 150px;
    padding-right: 200px;
    margin-bottom: 10rem;
  }
  @media screen and (min-width: 1600px) {
    padding-left: 200px;
    padding-right: 200px;
    margin-bottom: 10rem;
  }
  @media screen and (min-width: 1900px) {
    padding-left: 200px;
    padding-right: 200px;
    margin-bottom: 10rem;
  }
  @media screen and (min-width: 3000px) {
    display: grid;
    flex-direction: none;
    padding-top: 9rem;
    padding-left: 48rem;
    padding-right: 48rem;
  }

  .tintsMaterials__grid {
    background: #0c0c0c;
    width: 100%;
    display: grid;
    grid-template-columns: 800px 600px;
    grid-template-rows: 485px;
    grid-template-areas: 'image description';

    @media screen and (min-width: 275px) {
      display: flex;
      flex-direction: column;
      background: none;
    }
    @media screen and (min-width: 375px) {
      display: flex;
      flex-direction: column;
      background: none;
    }
    @media screen and (min-width: 696px) {
      display: flex;
      flex-direction: column;
      background: none;
    }
    @media screen and (min-width: 1300px) {
      display: grid;
      flex-direction: none;
      grid-template-columns: 700px 545px;
      background: none;
    }
    @media screen and (min-width: 1420px) {
      display: grid;
      flex-direction: none;
      grid-template-columns: 700px 565px;
      background: none;
    }
    @media screen and (min-width: 1600px) {
      display: grid;
      flex-direction: none;
      grid-template-columns: 800px 600px;
      background: none;
    }
    @media screen and (min-width: 1900px) {
      display: grid;
      flex-direction: none;
      grid-template-columns: 800px 600px;
      background: #0c0c0c;
    }

    .tintsMaterials__image {
      grid-area: image;

      .tintsMaterials__image__img {
        width: 100%;
        height: 485px;
        object-fit: cover;

        @media screen and (min-width: 275px) {
          height: 100%;
        }
        @media screen and (min-width: 375px) {
          height: 100%;
        }
        @media screen and (min-width: 696px) {
          height: 100%;
        }
        @media screen and (min-width: 1300px) {
          height: 485px;
        }
        @media screen and (min-width: 1420px) {
          height: 485px;
        }
        @media screen and (min-width: 1600px) {
          height: 485px;
        }
        @media screen and (min-width: 1900px) {
          height: 485px;
        }
      }
    }

    .tintsMaterials__description {
      background: var(--background-color);
      grid-area: description;
      padding: 0 90px;

      @media screen and (min-width: 275px) {
        padding: 0 0;
      }
      @media screen and (min-width: 375px) {
        padding: 0 0;
      }
      @media screen and (min-width: 696px) {
        padding: 0 0;
      }
      @media screen and (min-width: 1300px) {
        padding: 0 90px;
      }
      @media screen and (min-width: 1420px) {
        padding: 0 90px;
      }
      @media screen and (min-width: 1600px) {
        padding: 0 90px;
      }
      @media screen and (min-width: 1900px) {
        padding: 0 90px;
      }

      .tintsMaterials__description__inner {
        padding-top: 5rem;

        @media screen and (min-width: 270px) {
          padding-top: 1rem;
        }

        @media screen and (min-width: 375px) {
          padding-top: 1rem;
        }

        @media screen and (min-width: 696px) {
          padding-top: 1rem;
        }

        @media screen and (min-width: 1366px) {
          padding-top: 5rem;
        }

        @media screen and (min-width: 1440px) {
          padding-top: 5rem;
        }

        @media screen and (min-width: 1910px) {
          padding-top: 5rem;
        }

        h3 {
          text-transform: uppercase;
          font-weight: 400;
          letter-spacing: 2px;
          color: var(--secondary-color);
          font-size: 15px;
          position: relative;
          padding-left: 4.5rem;
          font-family: var(--font-base);

          @media screen and (min-width: 275px) {
            font-size: 12px;
          }
          @media screen and (min-width: 375px) {
            font-size: 12px;
          }
          @media screen and (min-width: 696px) {
            font-size: 12px;
          }
          @media screen and (min-width: 1300px) {
            font-size: 15px;
          }
          @media screen and (min-width: 1420px) {
            font-size: 15px;
          }
          @media screen and (min-width: 1600px) {
            font-size: 15px;
          }
          @media screen and (min-width: 1900px) {
            font-size: 15px;
          }
        }

        h3:before {
          content: '';
          display: block;
          width: 58px;
          height: 1px;
          background: var(--secondary-color);
          left: 1%;
          margin-top: 0.6rem;
          position: absolute;

          @media screen and (max-width: 400px) {
            width: 55px;
            margin-top: 0.4rem;
          }

          @media screen and (max-width: 696px) {
            width: 55px;
            margin-top: 0.4rem;
          }
        }

        h1 {
          text-transform: uppercase;
          font-weight: 600;
          letter-spacing: 6px;
          font-size: 50px;
          font-family: var(--font-base);
          padding-top: 0.5rem;

          @media screen and (min-width: 270px) {
            text-align: center;
            font-size: 30px;
            padding-top: 1rem;
          }

          @media screen and (min-width: 375px) {
            text-align: center;
            font-size: 30px;
            padding-top: 1rem;
          }

          @media screen and (min-width: 696px) {
            text-align: center;
            font-size: 30px;
            padding-top: 1;
          }

          @media screen and (min-width: 1366px) {
            font-size: 50px;
            text-align: left;
            padding-top: 0.5rem;
          }

          @media screen and (min-width: 1440px) {
            font-size: 50px;
            text-align: left;
            padding-top: 0.5rem;
          }

          @media screen and (min-width: 1910px) {
            font-size: 50px;
            text-align: left;
            padding-top: 0.5rem;
          }
        }

        p {
          text-transform: uppercase;
          font-size: 14px;
          color: var(--lightGray-color);
          font-family: var(--font-base);
          padding-top: 1rem;
          letter-spacing: 1px;

          @media screen and (min-width: 270px) {
            font-size: 13px;
          }

          @media screen and (min-width: 375px) {
            font-size: 13px;
          }

          @media screen and (min-width: 696px) {
            font-size: 13px;
          }

          @media screen and (min-width: 1366px) {
            font-size: 14px;
          }

          @media screen and (min-width: 1440px) {
            font-size: 14px;
          }

          @media screen and (min-width: 1910px) {
            font-size: 14px;
          }
        }
      }
    }
  }
}

span.anchor {
  display: block;
  position: relative;
  top: -250px;
  visibility: hidden;
}
