.socialIcons__box {
  @media screen and (max-width: 400px) {
    display: none;
  }

  @media screen and (max-width: 696px) {
    display: none;
  }

  .socialIcons__container {
    position: fixed;
    top: 80vh;
    z-index: 999;

    .socialIcons__icon {
      margin: 0 5px;
      text-align: center;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      position: relative;
      z-index: 2;

      span {
        position: relative;
        display: block;
        height: 50px;
        margin-left: 70px;
        z-index: 2;

        svg {
          width: 20px;
          height: 20px;
          color: var(--white-color);
          transition: color 400ms cubic-bezier(0.4, 0, 0.2, 1);
        }

        &:hover {
          svg {
            color: var(--secondary-color);
          }
        }
      }
    }
  }
}
